import React, { Component } from 'react';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
import withFileSize from 'components/hoc/FileSizeChecker/File';
// import { Icon } from 'semantic-ui-react';
import { withDocument, withTranslation } from '../../../hoc';
import { setMsgInfo } from '../../../../actions';
// import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { convertFromBytes, convertToBytes, MAX_STORAGE_SIZE } from 'utils';

const DropzoneWrapper = styled.label`
  width: auto;
  height: 50px;
  // background-color: #fff;
  background-color: var(--admincat-color-grey-1);
  border: 1px dashed #bcb3b3;
  display: flex;
  box-sizing: border-box;
  border-radius: 9px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.017em;
  color: var(--admincat-color-grey-12);
`;

const StyledFileInput = styled.input`
  display: none;
`;

class Dropzone extends Component {
  state = { highlight: false };
  files = [];

  onFilesAdded = (e) => {
    const files = Array.from(e.target.files || e.dataTransfer.files);
    const validFiles = this.validateFileTypes(files);

    if (validFiles.length > 0) {
      this.files = validFiles;
      const isValid = this.props.validateFileSize(this.files);
      if (isValid) {
        this.showForm();
      } else {
        this.files = [];
      }
    } else {
      this.files = [];
      this.props.setMsgInfo({
        success: false,
        msg: [this.props.tr('Only PDF, PNG, and JPEG files are allowed.')],
      });
    }
  };

  validateFileTypes = (files) => {
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg'];
    return files.filter((file) => allowedTypes.includes(file.type));
  };

  showForm() {
    const { toggleDocumentForm, showFormPage, sendDuplicateNotification } =
      this.props;
    toggleDocumentForm({ data: { files: this.files }, showForm: true });
    sendDuplicateNotification();
    showFormPage();
  }

  onDragOver = (e) => {
    e.preventDefault();
    this.setState({ highlight: true });
  };

  onDragLeave = () => {
    this.setState({ highlight: false });
  };

  onDrop = (e) => {
    e.preventDefault();
    this.onFilesAdded(e);
    this.setState({ highlight: false });
  };

  render() {
    const { props, state } = this;
    return (
      <DropzoneWrapper
        className={`dropzone ${state.highlight ? 'dropzone-highlight' : ''}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        htmlFor="files"
        style={{ cursor: props.disabled ? 'default' : 'pointer' }}
      >
        <StyledFileInput
          className="fileInput"
          type="file"
          multiple
          name="files"
          id="files"
          accept=".pdf,.png,.jpeg,.jpg"
          onChange={this.onFilesAdded}
        />
        <span>{props.tr('Drag and Drop files here or Upload files')}</span>
      </DropzoneWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch, { tr, totalFileSize: totalStorageSize }) => ({
  sendDuplicateNotification: () =>
    dispatch(
      setMsgInfo({
        success: true,
        msg: [tr('Please note duplicate files will be ignored')],
        navigationCounter: 1,
      }),
    ),

  validateFileSize: (files, maxFileSizeInMb = 25, maxTotalSizeInMb = 50) => {
    const maxFileSizeInBytes = maxFileSizeInMb * 1024 * 1024;
    const maxTotalSizeInBytes = maxTotalSizeInMb * 1024 * 1024;

    const currentUsageBytes = convertToBytes(totalStorageSize);
    const remainingStorage = MAX_STORAGE_SIZE - currentUsageBytes;

    if (remainingStorage <= 0) {
      dispatch(
        setMsgInfo({
          success: false,
          msg: [tr(`storage exceeded 100 mb. please clean up and remove files.`)],
        }),
      );
      return false;
    }

    if (files && files.length > 1) {
      const totalBatchSize = Array.from(files).reduce(
        (acc, file) => acc + file.size,
        0,
      );

      if (totalBatchSize > remainingStorage) {
        dispatch(
          setMsgInfo({
            success: false,
            msg: [tr(`storage exceeded 100 mb. please clean up and remove files.`)],
          }),
        );
        return false;
      }

      if (totalBatchSize > maxTotalSizeInBytes) {
        dispatch(
          setMsgInfo({
            success: false,
            msg: [
              `${tr(
                'the total size of the selected files exceeds the maximum allowed size of',
              )} ${maxTotalSizeInMb} MB.`,
            ],
          }),
        );
        return false;
      }
    } else if (files && files.length > 0) {
      if (files[0].size > remainingStorage) {
        dispatch(
          setMsgInfo({
            success: false,
            msg: [
              tr(
                `The file size you are trying to upload is ${convertFromBytes(
                  files[0].size,
                )} but there is only  ${convertFromBytes(
                  remainingStorage,
                )} of available storage space remaining. To complete your upload, please remove some files to free up space.`,
              ),
            ],
          }),
        );
        return false;
      }

      if (files[0].size > maxFileSizeInBytes) {
        dispatch(
          setMsgInfo({
            success: false,
            msg: [
              `${tr(
                'The file exceed the maximum allowed file size of',
              )} ${maxFileSizeInMb} MB.`,
            ],
          }),
        );
        return false;
      }
    }
    return true;
  },
  setMsgInfo: (msgInfo) => dispatch(setMsgInfo(msgInfo)),
});

export default compose(
  withDocument,
  withFileSize,
  withTranslation,
  connect(null, mapDispatchToProps),
)(Dropzone);
