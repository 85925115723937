/* eslint-disable */
import React from 'react';
import compose from 'lodash/fp/compose';
import {
  withTranslation,
  withCustomRouter,
  withUserPreferences,
  withTravelInfo,
} from 'components/hoc';
import MyDataContentTemplate from 'components/Dashboard/MyData/common/MyDataContentTemplate';
import LottieLoader from 'components/common/LottieLoader';
import {isEmptyValue } from 'components/utils';

const Travel = (props) => {
    const {travelInfo, fd, trObj } = props;

    const getAddress = (address_detail) => {
        const streetDetails = [
          address_detail.street_name,
          address_detail.street_no,
          address_detail.street_no_suffix,
        ]
          .filter((v) => !isEmptyValue(v))
          .join(' ');
    
        return [
          trObj(address_detail.country_detail),
          trObj(address_detail.city_detail),
          streetDetails,
        ]
          .filter((v) => !isEmptyValue(v))
          .join(', ');
      };

  const mapFields = () => {
    // const { travelInfo, fd, trObj } = props;
    console.log('travelInfo', travelInfo);

    return travelInfo.data.map((data) => {
      return {
        ...data,
        person: data?.person_detail?.first_name + ' ' + data?.person_detail?.last_name,
        address: getAddress(data?.address_detail),
        valid_from: fd(data?.valid_from),
        valid_to: fd(data?.valid_to),
        country: trObj(data?.country_detail),
        main_mot: trObj(data?.main_mot_detail),
        type_of_travel: trObj(data?.type_of_travel_detail),
      };
    });
  };

  const { loaded, deleteBulkTravel, gotoCreatePath } = props;

  // Add filterableColumns configuration
  const filterableColumns = [
    {
        columnId: 'description',
        columnDataType: 'text',
    },
    {
        columnId: 'type_of_travel',
        columnDataType: 'text',
    },
    {
        columnId: 'person',
        columnDataType: 'text',
    },
    {
        columnId: 'address',
        columnDataType: 'text',
    },
    {
        columnId: 'main_mot',
        columnDataType: 'text',
    },
    {
        columnId: 'country',
        columnDataType: 'text',
    },
    {
        columnId: 'valid_from',
        columnDataType: 'date',
    },
    {
        columnId: 'valid_to',
        columnDataType: 'date',
    },
  ];

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  return (
    <MyDataContentTemplate
      tableName="travel"
      columns={[]}
      data={mapFields() || []}
      handleCreateForm={gotoCreatePath}
      bulkDelete={deleteBulkTravel}
      filterableColumns={filterableColumns}
    />
  );
};

export default compose(
  withTranslation,
  withTravelInfo,
  withCustomRouter,
  withUserPreferences,
)(Travel);
