import React from 'react';
import compose from 'lodash/fp/compose';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import {
  withPersonalInfo,
  withCustomRouter,
  withTranslation,
  withOrganisation,
  withMsgInfo,
  withWorkAbsenceInfo,
  withAbsenceType,
} from 'components/hoc';
import {
  Select,
  Input,
  Form,
  InputDatePicker,
  ButtonLink,
} from 'components/common/FormItems';
import { useFavoriteOrganisation } from 'hooks/useFavoriteOrganisation';

const Wrapper = styled.div``;

const DualInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    min-width: 150px;
    flex: 1;
    margin-right: 1rem;

    :last-child {
      margin-right: 0px;
    }
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const StyledSelect = styled(Select)`
  padding-right: 2rem;
`;

const StyledInput = styled(Input)`
  padding-right: 1.5rem;
`;

const StyledInputDatePicker = styled(InputDatePicker)`
  padding-right: 1.7rem;
`;

const WorkAbsenceFormSchema = {
  person: {
    validation: 'required',
  },
  organisation: {
    validation: 'required',
  },
  valid_from: {
    validation: 'required',
  },
  valid_to: {
    validation: 'required',
  },
  absence_type: {
    validation: 'required',
  },
};

const searchById = (term, listItems) => {
  for (const item of listItems) {
    if (term == item?.id) {
      return item;
    }
  }
  return null;
};

const WorkAbsenceForm = (props) => {
  const {
    tr,
    trObj,
    basePath,
    routeParams,
    updateWorkAbsence,
    addWorkAbsence,
    getWorkAbsenceById,
    setMsgInfo,
    absenceTypeLocaleOptions,
  } = props;

  const [formData, setFormData] = React.useState({});
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);
  const [selectedIndustry, setSelectedIndustry] = React.useState('');

  const { id } = routeParams;
  const history = useHistory();
  const dispatch = useDispatch();

  const favoriteOrganisation = useFavoriteOrganisation();

  const fetchData = async () => {
    if (id) {
      try {
        setFormLoading(true);
        const data = await getWorkAbsenceById(id);
        setFormData({ ...data });
        setFormLoading(false);
      } catch (e) {
        setFormData({});
        setFormLoading(false);
      }
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (formData.organisation) {
      fetchIndustryByOrganisationId(formData.organisation);
    }
  }, [formData.organisation]);

  const fetchIndustryByOrganisationId = (orgId) => {
    const org = searchById(orgId, favoriteOrganisation);
    if (org) {
      const industryType = trObj(org?.organisation_data?.industry_type_detail);
      setSelectedIndustry(industryType);
    } else {
      setSelectedIndustry('');
    }
  };

  const onChange = (e) => {
    if (formSubmitting) return;
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    try {
      let response;
      setFormSubmitting(true);
      if (id) {
        response = await updateWorkAbsence(id, formData);
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${tr('work absence updated successfully')}`],
          }),
        );
      } else {
        response = await addWorkAbsence(formData);
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${tr('work absence added successfully')}`],
          }),
        );
      }
      setFormSubmitting(false);

      history.push(basePath);
      window.scrollTo(0, 0);

      if (response?.response?.status >= 400) return;
      return;
    } catch (e) {
      setFormSubmitting(false);
      return;
    }
  };

  return (
    <Wrapper className="form-wrapper">
      <div className="flex">
        <StyledButtonLink
          to={basePath}
          className="option-btn"
          icon="angle double left"
          name="All Entries"
        />
      </div>
      <div className="row">
        <div
          className="col-7 ml-10 mt-30 container"
          style={{
            position: 'relative',
            background: 'var(--admincat-color-grey-1)',
          }}
        >
          <br />

          <Form
            onSubmit={onSubmit}
            formData={formData}
            isLoading={formSubmitting}
            loadingData={formLoading}
            validationSchema={WorkAbsenceFormSchema}
            title="Absence"
          >
            <StyledSelect
              optionDefaultValue={tr('Select Person')}
              label={tr('Person')}
              value={formData?.person}
              name="person"
              onChange={onChange}
              options={props.personalInfoOptions}
              deselectable
              sort={false}
              showRequiredAsterisk
            />

            <StyledSelect
              optionDefaultValue={tr('Select Organization')}
              label={tr('Organization')}
              value={formData?.organisation}
              name="organisation"
              onChange={onChange}
              options={favoriteOrganisation}
              deselectable
              sort={true}
              showRequiredAsterisk
            />

            <StyledInput
              placeholder={tr('Industry')}
              label={tr('Industry')}
              value={selectedIndustry}
              name="industry"
              onChange={onChange}
              disabled
            />

            <DualInputWrapper>
              <InputDatePicker
                label="Valid from"
                value={formData.valid_from}
                name="valid_from"
                onChange={onChange}
                showRequiredAsterisk
              />
              <StyledInputDatePicker
                className="ml-sm-1 mr-md-1"
                label="Valid to"
                value={formData.valid_to}
                name="valid_to"
                onChange={onChange}
                showRequiredAsterisk
              />
            </DualInputWrapper>
            <StyledSelect
              optionDefaultValue={tr('Choose Absence type')}
              label={tr('Absence type')}
              value={formData?.absence_type}
              name="absence_type"
              onChange={onChange}
              localeOptions={absenceTypeLocaleOptions}
              deselectable
              sort={true}
              showRequiredAsterisk
            />

            <StyledInput
              className="mr-md-1"
              value={formData.comment || ''}
              onChange={onChange}
              name="comment"
              id="comment"
              placeholder="Enter Comment"
              label="Comment"
            />
          </Form>
        </div>
      </div>
    </Wrapper>
  );
};

export default compose(
  withPersonalInfo,
  withCustomRouter,
  withTranslation,
  withWorkAbsenceInfo,
  withOrganisation,
  withMsgInfo,
  withAbsenceType,
)(WorkAbsenceForm);
