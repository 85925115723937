import React from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import ContentTemplate from 'components/common/ContentTemplate';
import MyDataContentTemplate from 'components/Dashboard/MyData/common/MyDataContentTemplate';
import { DnDPortal } from 'components/common/FormItems/DnDPortal';
import {
  withCustomRouter,
  withTranslation,
  withTimeDependentName,
  withPersonNamesReason,
  withUserPreferences,
  withPersonalInfo,
  withUserLayoutChoice,
} from 'components/hoc';
import { ButtonLink, Button } from 'components/common/FormItems';
import { getFullName } from 'components/utils/getFullName';
import { PlusLg, PersonFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const StyledContentTemplate = styled(ContentTemplate)`
  div.table-responsive {
    height: auto;
    min-height: 400px;
    max-height: 600px;
    background: var(--admincat-color-grey-1);
  }
`;

const StyledButton = styled(Button)`
  // background: #eaedf1;
  background: var(--admincat-color-grey-6);
  border-radius: 2px;
  height: 38px;
  color: ${(props) => props.color || '#626467'};
  border: none;
  font-size: 14px;
  line-height: 10px;

  // remove this styles below,
  // if you want to see the full button when resized
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const defaultColumns = [
  {
    id: 'valid_from',
    label: 'Valid From',
  },
  {
    id: 'valid_to',
    label: 'Valid To',
  },
  {
    id: 'last_name',
    label: 'Last Name',
  },
  {
    id: 'reason',
    label: 'reason',
  },
  {
    id: '',
    label: '',
  },
];

// const options = ({ detail, _delete }) => {
//   return [
//     {
//       onClick: detail,
//       name: 'View',
//     },
//     {
//       onClick: _delete,
//       name: 'Delete',
//     },
//   ];
// };

const TimeDependentName = (props) => {
  const { getPersonalInfo, trObj, fd, getTimeDependentNames, routeParams } = props;
  const { personId } = routeParams;
  const [timeDependentName, setTimeDependentName] = React.useState([]);

  const fetchData = async () => {
    const data = await getTimeDependentNames({ person: personId });
    setTimeDependentName(data);
  };

  React.useEffect(() => {
    fetchData();
    return () => null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapFields = () => {
    return timeDependentName.map((data) => {
      return {
        ...data,
        space_name: data.space,
        valid_from: fd(data.valid_from),
        valid_to: fd(data.valid_to),
        reason: trObj(data.reason_detail),
        person: getFullName(data.person_detail),
      };
    });
  };
  const tableName = 'time_dependent_name';
  const frontendTableName = tableName;
  const backendTableName = tableName;
  const { getLayoutColumnProps } = props;
  const { columns, layoutColumns } = getLayoutColumnProps(
    frontendTableName,
    backendTableName,
    defaultColumns,
  );

  const { basePath, tr } = props;

  return (
    <React.Fragment>
      <div className="flex">
        <Link
          to={{
            pathname: `/dashboard/data/base/person`,
            state: {},
          }}
        >
          <StyledButton
            reactIcon={<PersonFill color="#2D7E10" size={12} className="mr-2" />}
            to={'person'}
            name={tr('Person')}
          />
        </Link>
        <Link
          to={{
            pathname: `${basePath}/create`,
            state: {},
          }}
        >
          <StyledButton
            reactIcon={<PlusLg color="#2D7E10" size={12} className="mr-2" />}
            to={'addName'}
            name={tr('Add Name')}
          />
        </Link>
        <DnDPortal tableName={frontendTableName} columns={layoutColumns} />
      </div>

       {/* <MyDataContentTemplate
            tableName="person"
            columns={[]}
            data={mapFields()}
            // handleCreateForm={gotoCreatePath}
            // bulkDelete={deleteBulkPersonalInfo}
            // filterableColumns={filterableColumns}
          /> */}

      <StyledContentTemplate
        raisedTable
        columns={columns}
        showDetailOnRowClick
        data={mapFields()}
      
      />
    </React.Fragment>
  );
};

export default compose(
  withCustomRouter,
  withTranslation,
  withTimeDependentName,
  withPersonNamesReason,
  withUserPreferences,
  withPersonalInfo,
  withUserLayoutChoice,
)(TimeDependentName);
