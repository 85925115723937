import Address from '../Housing/Address';
import AddressForm from './Address/Form';
import Residence from '../Housing/Residence';
import ResidenceForm from './Residence/Form';
import Contact from '../Housing/Contact';
import ContactForm from './Contact/Form';
import CitizenShip from '../Housing/Citizenship';
import CitizenshipForm from './Citizenship/Form';

import RealEstate from './RealEstate';
import RealEstateForm from './RealEstate/Form';

const path = '/dashboard/data/housing';

export default [
  {
    name: 'Address',
    path: `${path}/address`,
    icon: 'address book',
    component: Address,
    exact: true,
  },
  {
    name: 'Address Form',
    path: [
      `${path}/address/create`,
      `${path}/address/:id`,
      `${path}/address/:id/update`,
    ],
    icon: 'users',
    component: AddressForm,
    hide: true,
    exact: true,
  },
  {
    name: 'Residence',
    path: `${path}/residence`,
    icon: 'map marker',
    component: Residence,
    exact: true,
  },
  {
    name: 'Residence Form',
    path: [
      `${path}/residence/create`,
      `${path}/residence/:id`,
      `${path}/residence/:id/update`,
    ],
    icon: 'users',
    component: ResidenceForm,
    hide: true,
    exact: true,
  },
  {
    name: 'Contact',
    path: `${path}/contact`,
    icon: 'phone square',
    component: Contact,
    exact: true,
  },
  {
    name: 'Contact Form',
    path: [
      `${path}/contact/create`,
      `${path}/contact/:id`,
      `${path}/contact/:id/update`,
    ],
    icon: 'users',
    component: ContactForm,
    hide: true,
    exact: true,
  },
  {
    name: 'CitizenShip',
    path: `${path}/citizenship`,
    icon: 'globe',
    component: CitizenShip,
    exact: true,
  },
  {
    name: 'Citizenship Form',
    path: [
      `${path}/citizenship/create`,
      `${path}/citizenship/:id`,
      `${path}/citizenship/:id/update`,
    ],
    icon: 'users',
    component: CitizenshipForm,
    hide: true,
    exact: true,
  },
  {
    name: 'Real Estate',
    path: `${path}/real-estate`,
    icon: 'globe',
    component: RealEstate,
    exact: true,
  },
  {
    name: 'Real Estate Form',
    path: [
      `${path}/real-estate/create`,
      `${path}/real-estate/:id`,
      `${path}/real-estate/:id/update`,
    ],
    icon: 'globe',
    component: RealEstateForm,
    hide: true,
    exact: true,
  },
];

export const redirects = [
  {
    from: `${path}`,
    to: `${path}/address`,
  },
  {
    from: `${path}/*`,
    to: `${path}/addres`,
  },
];
