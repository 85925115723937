import React from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import * as accountAction from '../../../actions/account';
import { parseISO, differenceInDays } from 'date-fns';

let loaded = false;

export const withAccount = (WrapperComponent) => {
  class Account extends React.Component {
    componentDidMount() {
      const { setAuthUser } = this.props;
      // const { setAuthUser, getUserLanguage } = this.props;
      if (loaded) return;
      if (localStorage.token && localStorage.user) {
        setAuthUser({ ...JSON.parse(localStorage.user), isAuth: true });
        // getUserLanguage();
        loaded = true;
      }
    }

    redirectToLogin = () => {
      const { history } = this.props;
      history.replace('/login');
    };

    logout = () => {
      const { logout } = this.props;
      logout();
      this.redirectToLogin();
      window.location.reload();
    };
    login = (payload, redirectPath) => {
      const { login, history } = this.props;
      login(payload, history, redirectPath);
    };
    adminLogin = (payload, redirectPath) => {
      const { adminLogin, history } = this.props;
      adminLogin(payload, history, redirectPath);
    };
    validateOTP = (payload, redirectPath) => {
      const { validateOTP, history } = this.props;
      validateOTP(payload, history);
    };
    resendOTP = (payload) => {
      const { resendOTP, history } = this.props;
      resendOTP(payload, history);
    };

    render() {
      return (
        <WrapperComponent
          {...this.props}
          logout={this.logout}
          login={this.login}
          adminLogin={this.adminLogin}
          validateOTP={this.validateOTP}
          resendOTP={this.resendOTP}
        />
      );
    }
  }

  const mapStateToProps = ({ account }) => {
    // Calculate days until subscription ends and if within two weeks
    const calculateSubscriptionStatus = () => {
      const daysLeft = account?.user?.days_till_subscription_expiration;

      if (!daysLeft) {
        return {
          daysLeft: null,
          isWithinTwoWeeks: false,
        };
      }

      try {
        return {
          daysLeft: daysLeft,
          // we have change the logic to show the banner if the subscription ends in 2 days or less just for testing purposes
          isWithinTwoWeeks: daysLeft <= 21 && daysLeft > 0,
        };
      } catch (error) {
        console.error('Error calculating subscription end date:', error);
        return {
          daysLeft: null,
          isWithinTwoWeeks: false,
        };
      }
    };

    const { daysLeft, isWithinTwoWeeks } = calculateSubscriptionStatus();

    return {
      account,
      isUserAdmin: account?.user?.is_user_admin,
      isLoggedinAsAdmin: account?.user?.logged_in_as_admin,
      subscriptionEndDate: account?.user?.subscription_expiration_date,
      willSubscriptionEndInTwoWeeks: isWithinTwoWeeks,
      daysLeftUntilSubscriptionEnds: daysLeft,
      isPaymentNeeded:
        account?.user?.trial_period_left <= 0 && !account?.user?.has_active_plan,
    };
  };
  const WithAccount = connect(mapStateToProps, {
    ...accountAction,
    // getUserLanguage,
  })(Account);
  hoistNonReactStatics(WithAccount, WrapperComponent);
  return compose(withRouter)(WithAccount);
};
