import React from "react";
import { connect } from "react-redux";
import { getRealestateType } from "actions";
import hoistNonReactStatics from 'hoist-non-react-statics'

let loaded = false;

export const withRealestateType = (WrapperComponent) => {
  class WithRealestateType extends React.Component {
    componentDidMount() {
      const { getRealestateType } = this.props;
      if (loaded) return;
      loaded = true;
      getRealestateType();
    }

    getRealestateTypeOptions = () => undefined

    getRealestateTypeLocaleOptions = () => {
      const { realEstateType } = this.props;
      return realEstateType.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
            realEstateTypeOptions={this.getRealestateTypeOptions()} 
            realEstateTypeLocaleOptions={this.getRealestateTypeLocaleOptions()} 
            {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ realEstateType }) => ({ realEstateType })
  const WithRealestateTypeHOC = connect(mapStateToProps, {
    getRealestateType,
  })(WithRealestateType);
  hoistNonReactStatics(WithRealestateTypeHOC, WrapperComponent)
  return WithRealestateTypeHOC
}
