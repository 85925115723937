import React from "react";
import { connect } from "react-redux";
import { getOwnershipReason } from "actions";
import hoistNonReactStatics from 'hoist-non-react-statics'

let loaded = false;

export const withOwnershipReason = (WrapperComponent) => {
  class WithOwnershipReason extends React.Component {
    componentDidMount() {
      const { getOwnershipReason } = this.props;
      if (loaded) return;
      loaded = true;
      getOwnershipReason();
    }

    getOwnershipReasonOptions = () => undefined

    getOwnershipReasonLocaleOptions = () => {
      const { ownershipReason } = this.props;
      return ownershipReason.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
            ownershipReasonOptions={this.getOwnershipReasonOptions()} 
            ownershipReasonLocaleOptions={this.getOwnershipReasonLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ ownershipReason }) => ({ ownershipReason })
  const WithOwnershipReasonHOC = connect(mapStateToProps, {
    getOwnershipReason,
  })(WithOwnershipReason);
  hoistNonReactStatics(WithOwnershipReasonHOC, WrapperComponent)
  return WithOwnershipReasonHOC
}
