import React, { useState, useEffect } from 'react';
import compose from 'lodash/fp/compose';
import { useDispatch } from 'react-redux';
import { withTranslation, withAccount, withUserPreferences, withMsgInfo } from '../hoc';
import { Input, Button, Form } from '../common/AuthFormItems';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { OtpBody, OtpDivChild, OtpDivImage } from './style';

const SpacedButton = styled(Button)`
  margin-top: 2%;
  margin-bottom: 2%;
`;

const OTPActionButtons = ({ isLoading, countdown, t }) => {
  return (
    <React.Fragment>
      <Button type="submit" name={t('Submit')} isLoading={isLoading} />
    </React.Fragment>
  );
};

const OTP = (props) => {
  const { history, account } = props;
  const { isLoading, user } = account;
  const [otp, setOtp] = useState('');
  const [countdown, setCountdown] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // Set language from localStorage when component mounts
  useEffect(() => {
    const preferredLanguage = localStorage.getItem('preferred_language') || 'eng'; // Default to 'eng' if not set
    i18n.changeLanguage(preferredLanguage); // Update i18n language
  }, [i18n]);

  // Redirect to login if user_id is not present
  useEffect(() => {
    const user_id = localStorage.getItem('user_id');
    if (!user_id) {
      history.push('/login');
    }
  }, [history]);

  // Countdown timer logic
  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (countdown === 0) {
        clearInterval(countdownInterval);
        setIsResendDisabled(false);
      } else {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [countdown]);


  const handleResendClick = async () => {
    const { resendOTP, setMsgInfo } = props;
    const email = localStorage.getItem('user_email');
    const user_id = localStorage.getItem('user_id');
    const preferredLanguage = localStorage.getItem('preferred_language') || 'eng';
    i18n.changeLanguage(preferredLanguage); // Update i18n language

    try {
      await resendOTP({ email, user_id });
      // Dispatch success message with translated text
      dispatch(
        setMsgInfo({
          success: true,
          msg: [t('TOTP Resent please check your mail.')], 
        })
      );
      setIsResendDisabled(true);
      setCountdown(60);
    } catch (error) {
      console.error('Error resending OTP:', error);
    }
  };

  const onSubmit = (e, redirectPath) => {
    e.preventDefault();
    const { validateOTP } = props;
    if (isLoading) return;
    const email = localStorage.getItem('otp_email');
    const user_id = localStorage.getItem('user_id');
    const browser_id = localStorage.getItem('browserId');
    validateOTP({ otp, email, user_id, browser_id }, redirectPath);
  };

  const onChange = (e) => {
    setOtp(e.target.value);
  };

  return (
    <>
      <OtpBody>
        <OtpDivImage></OtpDivImage>

        <OtpDivChild>
          <Form
            title={t('Please enter your one-time code (TOTP) here:')}
            onSubmit={(e) => onSubmit(e)}
            renderActionButtons={() => (
              <OTPActionButtons
                isLoading={isLoading}
                countdown={countdown}
                t={t}
              />
            )}
            render={() => (
              <React.Fragment>
                <Input
                  icon="envelope"
                  onChange={onChange}
                  value={otp}
                  name="otp"
                  label="Otp"
                  placeholder={t('TOTP')}
                  required
                />
                <div>
                  {countdown <= 0 ? (
                    <SpacedButton
                      type="button"
                      name={t('Resend TOTP')}
                      isLoading={isLoading}
                      onClick={handleResendClick}
                      disabled={isResendDisabled}
                    />
                  ) : (
                    <p>{t('You can resend TOTP in {{countdown}} seconds', { countdown })}</p>
                  )}
                </div>
              </React.Fragment>
            )}
          />
        </OtpDivChild>
      </OtpBody>
    </>
  );
};

export default compose(withAccount, withTranslation, withUserPreferences, withMsgInfo)(OTP);