import React from 'react';
import compose from 'lodash/fp/compose';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import {
  withPersonalInfo,
  withCustomRouter,
  withTranslation,
  withOrganisation,
  withMsgInfo,
  withRealEstateInfo,
  withRealestateType,
  withMeasurementUnit,
  withAddressInfo,
  withOwnershipReason,
  withContract,
  withOrgFunctionAssignment,
} from 'components/hoc';
import {
  Select,
  Input,
  Form,
  InputDatePicker,
  ButtonLink,
  ColumnalSelect,
} from 'components/common/FormItems';

const Wrapper = styled.div``;

const DualInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    min-width: 150px;
    flex: 1;
    margin-right: 1rem;

    :last-child {
      margin-right: 0px;
    }
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const StyledSelect = styled(Select)`
  padding-right: 1rem;
`;

const StyledInput = styled(Input)`
    padding-right: 1rem;
`;

const StyledRegInstiSelect = styled(Select)`
  padding-right: 0.3rem;
`;


const StyledYearInput = styled(Input)`
    padding-right: 0.7rem;
`;

const StyledPageInput = styled(Input)`
    padding-right: 0.7rem;
`;

const StyledRegInput = styled(Input)`
    padding-right: 0.5rem;
`;

const StyledInputDatePicker = styled(InputDatePicker)`
  padding-right: 0.8rem;
`;

const StyledColumnalSelect = styled(ColumnalSelect)`
  padding-right: 1.0rem;
`;

const RealEstateFormSchema = {
  realestate_type: {
    validation: 'required',
  },
  total_land: {
    validation: 'required',
  },
  tot_land_measure: {
    validation: 'required',
  },
  associated_share: {
    validation: 'required',
  },
  construction_year: {
    validation: 'required',
  },
  cadastral_register: {
    validation: 'required',
  },
  page: {
    validation: 'required',
  },
  reg_number: {
    validation: 'required',
  },

  valid_from: {
    validation: 'required',
  },
  valid_to: {
    validation: 'required',
  },
};

const addressLabels = [
  { _country: 'Country' },
  { _city: 'City' },
  { _street: 'Street' },
];

const RealEstateForm = (props) => {
  const {
    tr,
    trObj,
    basePath,
    routeParams,
    updateRealEstate,
    addRealEstate,
    getRealEstateById,
    setMsgInfo,
    realEstateTypeLocaleOptions,
    surfaceMeasurementUnitLocaleOptions,
    ownershipReasonLocaleOptions,
    contractInfoOptions,
    orgFunctionAssignment: { register_institute_options },
  } = props;

  const [formData, setFormData] = React.useState({});
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);

  const { id } = routeParams;
  const history = useHistory();
  const dispatch = useDispatch();


  const getAddressInfo = () => {
    return props.addressInfo.data.map(
      ({
        country_detail,
        city_detail,
        street_name,
        street_no,
        street_no_suffix,
        ...rest
      }) => ({
        ...rest,
        _country: trObj(country_detail),
        _city: trObj(city_detail),
        _street_name: street_name,
        _street_no: street_no,
        _street_no_suffix: street_no_suffix,
      }),
    );
  };

  const fetchData = async () => {
    if (id) {
      try {
        setFormLoading(true);
        const data = await getRealEstateById(id);
        setFormData({ ...data });
        setFormLoading(false);
      } catch (e) {
        setFormData({});
        setFormLoading(false);
      }
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const onChange = (e) => {
    if (formSubmitting) return;
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    try {
      let response;
      setFormSubmitting(true);
      if (id) {
        response = await updateRealEstate(id, formData);
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${tr('real estate updated successfully')}`],
          }),
        );
      } else {
        response = await addRealEstate(formData);
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${tr('real estate added successfully')}`],
          }),
        );
      }
      setFormSubmitting(false);

      history.push(basePath);
      window.scrollTo(0, 0);

      if (response?.response?.status >= 400) return;
      return;
    } catch (e) {
      setFormSubmitting(false);
      return;
    }
  };

  const registerInstituteOptions = React.useMemo(() => {
    // uniquelize the organisations by name
    const map = new Map();
    register_institute_options.forEach((props) => {
      const { name } = props;
      map.set(name, props);
    });
    return Array.from(map.values());
  }, [register_institute_options]);


  return (
    <Wrapper className="form-wrapper">
      <div className="flex">
        <StyledButtonLink
          to={basePath}
          className="option-btn"
          icon="angle double left"
          name="All Entries"
        />
      </div>
      <div className="row">
        <div
          className="col-7 ml-10 mt-30 container"
          style={{
            position: 'relative',
            background: 'var(--admincat-color-grey-1)',
          }}
        >
          <br />

          <Form
            onSubmit={onSubmit}
            formData={formData}
            isLoading={formSubmitting}
            loadingData={formLoading}
            validationSchema={RealEstateFormSchema}
            title="Real Estate"
          >
            <StyledSelect
              optionDefaultValue={tr('Choose Real Estate Type')}
              label={tr('Real Estate Type')}
              value={formData?.realestate_type}
              name="realestate_type"
              onChange={onChange}
              localeOptions={realEstateTypeLocaleOptions}
              deselectable
              sort={true}
              showRequiredAsterisk
            />

            <DualInputWrapper>
              <Input
                placeholder={tr('Size')}
                label={tr('Habitable Surface')}
                name="hab_surface"
                onChange={onChange}
                value={formData?.hab_surface}
              />
              <StyledSelect
                optionDefaultValue={tr('Choose Measurement')}
                label={tr('Measurement')}
                value={formData?.hab_surf_measure}
                name="hab_surf_measure"
                onChange={onChange}
                localeOptions={surfaceMeasurementUnitLocaleOptions}
                deselectable
                sort={true}
                // style={{paddingBottom: "0.5rem"}}
              />
            </DualInputWrapper>

            <DualInputWrapper>
              <Input
                placeholder={tr('Size')}
                label={tr('Total Land')}
                name="total_land"
                onChange={onChange}
                value={formData?.total_land}
                showRequiredAsterisk
              />
              <StyledSelect
                optionDefaultValue={tr('Choose Measurement')}
                label={tr('Measurement')}
                value={formData?.tot_land_measure}
                name="tot_land_measure"
                onChange={onChange}
                localeOptions={surfaceMeasurementUnitLocaleOptions}
                deselectable
                sort={true}
                showRequiredAsterisk
              />
            </DualInputWrapper>
            <DualInputWrapper>
              <Input
                placeholder={tr('Share')}
                label={tr('Associated Share (land)')}
                name="associated_share"
                onChange={onChange}
                value={formData?.associated_share}
                showRequiredAsterisk
              />
              <StyledYearInput
                placeholder={tr('Year')}
                label={tr('Year of Construction')}
                name="construction_year"
                onChange={onChange}
                value={formData.construction_year}
                showRequiredAsterisk
              />
            </DualInputWrapper>

            <DualInputWrapper>
              <StyledRegInstiSelect
                optionDefaultValue={tr('Register Institute')}
                label={tr('Cadastral Register')}
                value={formData?.cadastral_register}
                name="cadastral_register"
                onChange={onChange}
                // localeOptions={registerInstitutesOptions}
                // options={favoriteOrganisation}
                options={registerInstituteOptions}
                deselectable
                sort={true}
                showRequiredAsterisk
              />
              <StyledPageInput
                placeholder={tr('Page')}
                label={tr('Page')}
                name="page"
                onChange={onChange}
                value={formData.page}
                showRequiredAsterisk
              />
            </DualInputWrapper>
            <StyledRegInput
              placeholder={tr('Register Number')}
              label={tr('Register Number')}
              name="reg_number"
              onChange={onChange}
              value={formData.reg_number}
              showRequiredAsterisk
            />
            <StyledColumnalSelect
              optionDefaultValue={tr('Choose Address')}
              onChange={onChange}
              value={formData?.address || ''}
              name="address"
              label="Address"
              showRequiredAsterisk
              labels={addressLabels}
              options={getAddressInfo()}
            />
            <DualInputWrapper>
              <InputDatePicker
                label="Valid from"
                value={formData.valid_from}
                name="valid_from"
                onChange={onChange}
                showRequiredAsterisk
              />
              <StyledInputDatePicker
                className="ml-sm-1 mr-md-1"
                label="Valid to"
                value={formData.valid_to}
                name="valid_to"
                onChange={onChange}
                showRequiredAsterisk
              />
            </DualInputWrapper>

            <StyledSelect
              optionDefaultValue={tr('Reason of Ownership')}
              label={tr('Reason of Ownership')}
              value={formData?.ownership_reason}
              name="ownership_reason"
              onChange={onChange}
              localeOptions={ownershipReasonLocaleOptions}
              deselectable
              sort={true}
            />

            <StyledSelect
              optionDefaultValue={tr('Contract')}
              label={tr('Purchase Contract (If any)')}
              value={formData?.contract}
              name="contract"
              onChange={onChange}
              options={contractInfoOptions}
              deselectable
              sort={true}
            />
          </Form>
        </div>
      </div>
    </Wrapper>
  );
};

export default compose(
  withPersonalInfo,
  withCustomRouter,
  withTranslation,
  withRealEstateInfo,
  withOrganisation,
  withMsgInfo,
  withRealestateType,
  withMeasurementUnit,
  withAddressInfo,
  withOwnershipReason,
  withContract,
  withOrgFunctionAssignment,
)(RealEstateForm);
