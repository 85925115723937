/* eslint-disable */
import React from 'react';
import compose from 'lodash/fp/compose';
import {
  withTranslation,
  withCustomRouter,
  withUserPreferences,
} from 'components/hoc';
import MyDataContentTemplate from 'components/Dashboard/MyData/common/MyDataContentTemplate';
import LottieLoader from 'components/common/LottieLoader';
import { withAutoMobileInfo } from 'components/hoc/AutoMobileInfo';

const AutoMobile = (props) => {
  const mapFields = () => {
    const { autoMobileInfo, trObj, fd } = props;
    
    return autoMobileInfo.data.map((data) => {
      
      return {
        ...data,
        id: data.id,
        auto_brand: trObj(data?.auto_brand_detail),
        means_of_transport_category: trObj(data?.means_of_transport_category_detail),
        measurement_unit: trObj(data?.measurement_unit_detail),
        color: trObj(data?.color_detail),
        energy_source: trObj(data?.energy_source_detail),
        constriction_year: trObj(data?.construction_year_detail),        
        initial_reg_date: fd(data.initial_reg_date),
        model_series_type: data?.model_series_type

      };
    });
  };

  const { loaded, deleteBulkAutoMobileInfo, gotoCreatePath } = props;

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  // Add filterableColumns configuration
  const filterableColumns = [
    {
      columnId: 'auto_brand',
      columnDataType: 'text',
    },
    {
      columnId: 'means_of_transport_category',
      columnDataType: 'text',
    },
    {
      columnId: 'measurement_unit',
      columnDataType: 'text',
    },
    {
      columnId: 'color',
      columnDataType: 'text',
    },
    {
      columnId: 'energy_source',
      columnDataType: 'text',
    },
    {
      columnId: 'construction_year',
      columnDataType: 'text',
    },
  
    {
      columnId: 'initial_reg_date',
      columnDataType: 'date',
    },
    {
      columnId: 'model_series',
      columnDataType: 'text',
    },
    {
      columnId: 'type_model_spec',
      columnDataType: 'text',
    },
    {
      columnId: 'max_speed',
      columnDataType: 'number',
    },
    {
      columnId: 'power',
      columnDataType: 'number',
    },
    {
      columnId: 'cubic_capacity',
      columnDataType: 'number',
    },
    {
      columnId: 'no_of_doors',
      columnDataType: 'number',
    },
    {
      columnId: 'no_of_seats',
      columnDataType: 'number',
    },
    {
      columnId: 'vehicle_no',
      columnDataType: 'text',
    },
   
  ];

  return (
    <React.Fragment>
    <MyDataContentTemplate
      tableName="auto_mobile"
      columns={[]}
      // data={mapFields()}
      data={mapFields() || []}
      handleCreateForm={gotoCreatePath}
      bulkDelete={deleteBulkAutoMobileInfo}
      filterableColumns={filterableColumns}
    />
    </React.Fragment>
  );
};

export default compose(
  withTranslation,
  withAutoMobileInfo,
  withCustomRouter,
  withUserPreferences,
)(AutoMobile);
