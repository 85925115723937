import React from 'react';
import { Line } from 'react-chartjs-2';
import compose from 'lodash/fp/compose';
// import styled from 'styled-components';
import { withTranslation, withUserPreferences } from 'components/hoc';
import { useDarkMode } from 'context/DarkModeContext';

import styles from './chart.module.css';

const AssetVsLiabilityLineChart = ({
  dataList_1_year,
  assetCategoryList_1_year,
  liabilityCategoryList_1_year,
  dataList_3_year,
  assetCategoryList_3_year,
  liabilityCategoryList_3_year,
  dataList_5_year,
  assetCategoryList_5_year,
  liabilityCategoryList_5_year,
  dataList_10_year,
  assetCategoryList_10_year,
  liabilityCategoryList_10_year,
  tr,
  fn,
  xfn,
}) => {
  const [interval, setInterval] = React.useState('year_1');
  const [selectedCategory, setSelectedCategory] = React.useState(
    'Assets vs Liabilities',
  );

  const { isDarkMode } = useDarkMode();

  const asset_liability =
    interval == 'year_1'
      ? dataList_1_year
      : interval == 'year_3'
      ? dataList_3_year
      : interval == 'year_5'
      ? dataList_5_year
      : interval == 'year_10'
      ? dataList_10_year
      : [];

  const assetList =
    interval == 'year_1'
      ? assetCategoryList_1_year
      : interval == 'year_3'
      ? assetCategoryList_3_year
      : interval == 'year_5'
      ? assetCategoryList_5_year
      : interval == 'year_10'
      ? assetCategoryList_10_year
      : [];

  const liabilityList =
    interval == 'year_1'
      ? liabilityCategoryList_1_year
      : interval == 'year_3'
      ? liabilityCategoryList_3_year
      : interval == 'year_5'
      ? liabilityCategoryList_5_year
      : interval == 'year_10'
      ? liabilityCategoryList_10_year
      : [];

  const chartData = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          color: isDarkMode ? '#ffffff' : '#68696a', // Change legend text color
          font: {
            size: 14,
          },
        },
      },
    },

    scales: {
      x: {
        ticks: {
          color: isDarkMode ? '#ffffff' : '#68696a', // Change X-axis text color
        },
        grid: {
          color: isDarkMode ? '#bbbbbb' : 'rgb(214, 215, 216)',
        },
      },
      y: {
        ticks: {
          color: isDarkMode ? '#ffffff' : '#68696a', // Change Y-axis text color
        },
        grid: {
          color: isDarkMode ? '#bbbbbb' : 'rgb(214, 215, 216)',
        },
      },
    },
  };

  const data = {
    labels: asset_liability?.map((data) => data?.date),

    datasets: [
      {
        label: tr('Assets'),
        type: 'line',
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        data: asset_liability?.map((p) => p?.assets?.toFixed(2)) || [],
        fill: false,
        // tension: 0.4,
      },
      {
        label: tr('Liabilities'),
        type: 'line',
        borderColor: '#8e5ea2',
        backgroundColor: '#8e5ea2',
        data: asset_liability?.map((p) => p?.liabilities?.toFixed(2)) || [],
        fill: false,
        // tension: 0.4,
      },
    ],
  };

  let data_ =
    selectedCategory == 'Assets vs Liabilities'
      ? data
      : selectedCategory == 'Assets'
      ? assetList
      : liabilityList;

  return (
    <div>
      <div className={styles['interval_btn_container']}>
        <select
          value={interval}
          onChange={({ target }) => setInterval(target.value)}
          style={{
            height: 38,
            transition: 'all ease-in-out 300ms',
            borderRadius: 2,
            background: '#d5e4f7',
            color: '#4576b3',
            fontSize: 14,
            overflow: 'hidden',
            fontWeight: 500,
            padding: 10,
            border: 0,
          }}
        >
          <option value="year_1">{tr('1 year')}</option>
          <option value="year_3">{tr('3 years')}</option>
          <option value="year_5">{tr('5 years')}</option>
          <option value="year_10">{tr('10 years')}</option>
        </select>

        <select
          value={selectedCategory}
          onChange={({ target }) => setSelectedCategory(target.value)}
          style={{
            height: 38,
            transition: 'all ease-in-out 300ms',
            borderRadius: 2,
            background: '#d5e4f7',
            color: '#4576b3',
            fontSize: 14,
            overflow: 'hidden',
            fontWeight: 500,
            padding: 10,
            border: 0,
          }}
        >
          <option value="Assets vs Liabilities">
            {tr('Assets vs Liabilities')}
          </option>

          <option value="Assets">{tr('Assets')}</option>

          <option value="Liabilities">{tr('Liabilities')}</option>
        </select>
      </div>

      <Line data={data_} options={chartData} height={130} />
    </div>
  );
};

export default compose(
  withTranslation,
  withUserPreferences,
)(AssetVsLiabilityLineChart);
