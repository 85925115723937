import React, { Component } from 'react';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { withDeleteConfirmation, withPersonalInfo, withTranslation } from '..';
import { bankAccountActions, createAction, setMsgInfo } from '../../../actions';
import * as types from '../../../actions/dashboard/finance/types';
import { transFormInputData } from './utils';
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC";

export const withBankAccount = (WrappedComponent) => {
  class WithBankAccount extends BaseHOC {
    state = {
      loaded: false,
    };
    constructor(props) {
      super(props);
    }

    async componentDidMount() {
      if (this.state.loaded) return;
      await this.props.getAllBankAccount();
      this.setState({ ...this.state, loaded: true });
    }

    toggleForm = (data) => {
      const {
        bankAccountForm: { showForm },
      } = this.props;
      if (!showForm && data) {
        this.props.getBankAccountById(data.id);
      }
      this.props.setValue({ data: {}, showForm: !showForm });
    };

    togglePersonalBankAccountView = (data) => {
      const {
        bankAccountView: { showView },
      } = this.props;
      this.props.toggleViewModal({ data: {}, showView: !showView });
      if (!showView && data) {
        this.props.getBankAccountById(data.id);
      }
    };

    createBankAccount = async (data) => {
      const {
        data: { id },
      } = this.props.bankBIC;
      const accountData = transFormInputData(data, id);
      const res = await this.props.createBankAccount(accountData);
      this.props.getAllBankAccount();
      return res;
    };

    // updateBankAccount = async (data) => {
    //   const {
    //     singleBankAccount: {
    //       id,
    //       BIC_data: { id: orgId },
    //     },
    //     updateBankAccount,
    //   } = this.props;
    //   const accountData = transFormInputData(data, orgId);
    //   const res = await updateBankAccount(accountData, id);
    //   return res;
    // };


    updateBankAccount = async (id, data) => {
      try {
        // Get the orgId from the data passed or from the props
        let orgId;
        
        if (this.props.singleBankAccount && this.props.singleBankAccount.BIC_data) {
          orgId = this.props.singleBankAccount.BIC_data.id;
        } else if (this.props.bankBIC && this.props.bankBIC.data) {
          orgId = this.props.bankBIC.data.id;
        } else {
          console.error("Missing BIC data for updating bank account");
          return;
        }
        
        const accountData = transFormInputData(data, orgId);
        const res = await this.props.updateBankAccount(id, accountData);
        return res;
      } catch (error) {
        console.error("Error updating bank account:", error);
        throw error;
      }
    };

    deleteBankAccountById = async (data) => {
      const { deleteConfirmation, deleteBankAccountById } = this.props;
      try {
        await deleteConfirmation.init(deleteBankAccountById, data);
      } catch (e) {}
    };

    // deleteBulkBankAccount = async (data) => {
    //   const { deleteConfirmation, deleteBulkBankAccount, tr } = this.props;
    //   try {
    //     await deleteConfirmation.initBulk(deleteBulkBankAccount, data);
    //   } catch (e) {}
    // };

    deleteBulkBankAccount = async (data) => {
      const { deleteBulkBankAccount } = this.props
      await this._deleteBulk(deleteBulkBankAccount, data);
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          mode={this.state.mode}
          loaded={this.state.loaded}
          toggleForm={this.toggleForm}
          togglePersonalBankAccountView={this.togglePersonalBankAccountView}
          // getOrganizationFromBic={this.getOrganizationFromBic}
          createBankAccount={this.createBankAccount}
          updateBankAccount={this.updateBankAccount}
          deleteBankAccountById={this.deleteBankAccountById}
          deleteBulkBankAccount={this.deleteBulkBankAccount}
        />
      );
    }
  }

  function mapStateToProps(state) {
    return {
      ...state.bankAccount,
      // bankAccountForm: state.bankAccountForm,
      // bankAccountView: state.bankAccountView,
    };
  }

  const BankAccount = connect(mapStateToProps, {
    ...bankAccountActions,
    setValue: (data) => createAction(types.BANK_ACCOUNT_FORM, data),
    clearSingleBankData: () => createAction(types.CLEAR_SINGLE_BANK_ACCOUNT_DATA),
    // toggleViewModal: (data) => createAction(types.BANK_ACCOUNT_FORM_VIEW, data)
  })(WithBankAccount);
  hoistNonReactStatics(BankAccount, WrappedComponent);
  return compose(withBaseHOCProps, withPersonalInfo, withTranslation)(BankAccount);
};
