import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';

import { documentSlice } from 'rootStateHandler/selectors';
import { delay } from 'lodash';
import {
  getTaskIdThunk,
  listDocumentsThunk,
  uploadStatusThunk,
} from '../documentStates/documentThunk';
import { uploadStatus } from '../documentStates/documentSlice';
import { NodeMinusFill } from 'react-bootstrap-icons';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoaderWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  z-index: 1000;
`;

const Spinner = styled.div`
  width: 24px;
  height: 24px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
  margin-right: 12px;
`;

const Message = styled.p`
  margin: 0;
  font-size: 14px;
`;

const POLL_INTERVAL = 5000; // 5 seconds polling interval
const SUCCESS_DELAY = 2000; // Delay before updating status on success

const FloatingDocumentUplaodLoader = () => {
  const { upload_status, task_id } = useSelector(documentSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log({ upload_status });
    console.log({ task_id });
    if (task_id) {
      pollTaskStatus(task_id);
    }
  }, [task_id, upload_status]);

  const pollTaskStatus = async (taskId) => {
    console.log('Polling task status for taskId:', taskId);

    console.log('pulling task status: ', upload_status);
    console.log('pulling task task_id: ', task_id);

    try {
      // Handling task statuses
      if (upload_status === 'success') {
        console.log('Task completed successfully. Status:', upload_status);

        // Dispatch necessary actions upon success
        dispatch(listDocumentsThunk());
        delay(() => {
          dispatch(uploadStatus('finished')); // Mark upload as finished
          dispatch(getTaskIdThunk(null)); // Clear task ID
        }, SUCCESS_DELAY);
      } else if (upload_status === 'failure') {
        console.log('Task failed. Status:', upload_status);

        // Dispatch failure status
        dispatch(uploadStatus('failed'));
        dispatch(getTaskIdThunk(null)); // Clear task ID on failure
      } else {
        // Continue polling if the task is still processing
        console.log('Task is still processing. Polling again...');
        dispatch(uploadStatusThunk(taskId));

        // Recurse after the polling interval
        setTimeout(() => pollTaskStatus(taskId), POLL_INTERVAL);
      }
    } catch (error) {
      console.error('Error occurred while polling task status:', error);
      // Dispatch failure status if there's an error
      dispatch(uploadStatus('failed'));
      dispatch(getTaskIdThunk(null)); // Clear task ID on error
    }
  };

  // const pollTaskStatus = async (taskId) => {
  //   // dispatch(uploadStatusThunk(taskId));
  //   console.log('pulling task status ');
  //   console.log('pulling task status: ', upload_status);
  //   console.log('pulling task task_id: ', task_id);
  //   try {
  //     if (upload_status === 'success') {
  //       console.log('Done processing status: ', upload_status);
  //       dispatch(listDocumentsThunk());
  //        delay(() => {
  //         dispatch(uploadStatus('finished'));
  //         dispatch(getTaskIdThunk(null));
  //       }, 2000);

  //       // Handle completion (e.g., show success message, update document list)
  //     } else if (upload_status === 'failure') {
  //       dispatch(uploadStatus('failed'));
  //       // Handle failure
  //     } else {
  //       // Continue polling
  //       console.log('Continue pulling task status ');
  //       dispatch(uploadStatusThunk(taskId));
  //       setTimeout(() => {
  //         pollTaskStatus(taskId);
  //       }, 5000);
  //     }
  //   } catch (error) {
  //     console.error('Error checking task status:', error);
  //     dispatch(uploadStatus('failure'));
  //     dispatch(getTaskIdThunk(null));
  //   }
  // };

  return (
    <>
      {upload_status === 'waiting' ? null : (
        <LoaderWrapper>
          <Spinner />
          <Message>
            {upload_status === 'SUCCESS'
              ? 'Documents processed successfully!'
              : upload_status === 'FAILURE'
              ? 'An error occurred while processing the documents.'
              : 'Document(s) are still being processed...'}
          </Message>
        </LoaderWrapper>
      )}
    </>
  );
};

export default FloatingDocumentUplaodLoader;
