import React from 'react';
import compose from 'lodash/fp/compose';
import {
  withTranslation,
  withSpace,
  withSpaceMembers,
  withUserPreferences,
  withCustomRouter,
} from '../../../hoc';
import SpaceContentTemplate from 'components/Dashboard/Admin/common/SpaceContentTemplate';

// const defaultColumns = [
//   {
//     id: 'user',
//     label: 'Username',
//   },
//   {
//     id: 'space',
//     label: 'Space',
//   },
//   {
//     id: 'request_date',
//     label: 'Request date',
//   },
//   {
//     id: 'status',
//     label: 'Status',
//   },
//   {
//     id: 'decision_date',
//     label: 'Decision date',
//   },
// ];

const SpaceAdmin = (props) => {
  const mapFields = () => {
    const { spaceMembers, fd } = props;

    return spaceMembers.map((data) => {
      return {
        ...data,
        space: data.space.space_name,
        status: data.status,
        decision_date: fd(data.decision_date),
        request_date: fd(data.request_date),
        user: data.user.username,
        admin_change_requested: data.admin_change_requested,
      };
    });
  };

  const { hasBulkDelete, tr, ...rest } = props;

  return (
    <React.Fragment>
      <SpaceContentTemplate
        tableName="space_member"
        columns={[]}
        hasBulkDelete
        data={mapFields()}
      />
    </React.Fragment>
  );
};

export default compose(
  withTranslation,
  withSpace,
  withSpaceMembers,
  withUserPreferences,
  withCustomRouter,
)(SpaceAdmin);
