import {
  documentDetailsService,
  documentFullTextSearchService,
  filterCriteriaService,
  getDocumentsService,
  getDocumentCategoryService,
  getDocumentTypeService,
  getPersonsService,
  getFavoriteFiltersService,
  documentUploadStatus,
} from 'components/Dashboard/Documents/services/documentServices';
import {
  getFilterCriteria,
  filteredDocuments,
  filterFailed,
  documentDetailsState,
  getDocumentDetailFailed,
  getSearchResult,
  searchResultState,
  getSearchResultFailed,
  getDocumentList,
  getPersons,
  listDocumentsState,
  getDocumentListFailed,
  onFiltering,
  getDocumentCategory,
  getDocumentTypes,
  getFavoriteFilters,
  getFavoriteFiltersFailed,
  listFavoriteFiltersState,
  taskId,
  uploadStatus,
} from './documentSlice';

export const filterCriteriaThunk = (filter_param) => async (dispatch) => {
  try {
    // Clean the filter params to ensure that only valid, non-empty filters are passed
    const cleanedParams = Object.keys(filter_param)
      .filter(
        (key) => Array.isArray(filter_param[key]) && filter_param[key].length !== 0,
      )
      .reduce((acc, key) => {
        acc[key] = filter_param[key];
        return acc;
      }, {});

    // Prepare keys and values to be sent to the backend
    const filter_param_key = Object.keys(cleanedParams);
    const filter_param_value = [].concat(...Object.values(cleanedParams));

    dispatch(getFilterCriteria());

    const data = await filterCriteriaService(filter_param_key, filter_param_value);

    dispatch(filteredDocuments(data));
  } catch (error) {
    dispatch(filterFailed());
  }
};

export const onFilterCriteriaThunk = (isFiltering) => async (dispatch) => {
  try {
    dispatch(onFiltering(isFiltering));
  } catch (error) {
    throw error;
  }
};

export const documentDetailsThunk = (id) => async (dispatch) => {
  try {
    const data = await documentDetailsService(id);

    dispatch(documentDetailsState(data));
  } catch (error) {
    dispatch(getDocumentDetailFailed());
  }
};

export const documentClearDetailsThunk = () => async (dispatch) => {
  try {
    dispatch(documentDetailsState({}));
  } catch (error) {
    dispatch(getDocumentDetailFailed());
  }
};

export const fullTextSearchThunk = (search_query) => async (dispatch) => {
  try {
    dispatch(getSearchResult());

    const data = await documentFullTextSearchService(search_query);

    dispatch(searchResultState(data));
  } catch (error) {
    dispatch(getSearchResultFailed());
  }
};

export const listDocumentsThunk = () => async (dispatch) => {
  try {
    dispatch(getDocumentList());

    const data = await getDocumentsService();

    dispatch(listDocumentsState(data));
  } catch (error) {
    dispatch(getDocumentListFailed());
  }
};

export const listDocumentCategoriesThunk = () => async (dispatch) => {
  try {
    const data = await getDocumentCategoryService();

    dispatch(getDocumentCategory(data));
  } catch (error) {
    throw error;
  }
};

export const listDocumentTypesThunk = () => async (dispatch) => {
  try {
    const data = await getDocumentTypeService();

    dispatch(getDocumentTypes(data));
  } catch (error) {
    throw error;
  }
};

export const listPersonsThunk = () => async (dispatch) => {
  try {
    const data = await getPersonsService();

    dispatch(getPersons(data));
  } catch (error) {
    throw error;
  }
};

export const listFavoriteFiltersThunk = () => async (dispatch) => {
  try {
    dispatch(getFavoriteFilters());

    const data = await getFavoriteFiltersService();

    dispatch(listFavoriteFiltersState(data));
  } catch (error) {
    dispatch(getFavoriteFiltersFailed());

    throw error;
  }
};

export const getTaskIdThunk = (task_Id) => (dispatch) => {
  dispatch(taskId(task_Id));
};

export const uploadStatusThunk = (task_id) => async (dispatch) => {
  try {
    console.log('calling [uploadStatusThunk]');
    const status = await documentUploadStatus(task_id);
    console.log('calling [uploadStatusThunk]: ', status);
    dispatch(uploadStatus(status));
  } catch (error) {
    dispatch(uploadStatus('failed'));
    console.error('[uploadStatusThunk] error: ', error);
    throw error;
  }
};
