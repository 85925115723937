export const convertToBytes = (sizeString) => {
  const units = {
    B: 1,
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
  };
  const [size, unit] = sizeString?.split(/(\d+(?:\.\d+)?)/)?.filter(Boolean);
  return parseFloat(size) * units[unit.trim()];
};

// export const MAX_STORAGE_SIZE = 100 * 1024 * 1024;
export const MAX_STORAGE_SIZE = 100 * 1024 * 1024;

export const convertFromBytes = (bytes) => {
  // Check if bytes is null, undefined, or not a number
  if (bytes == null || isNaN(bytes)) {
    return null; // Or you can return any other message or value you prefer
  }

  if (bytes < 1024) {
    return `${bytes} B`; // Less than 1 KB
  } else if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(2)} KB`; // Less than 1 MB
  } else if (bytes < 1024 * 1024 * 1024) {
    return `${(bytes / (1024 * 1024)).toFixed(2)} MB`; // Less than 1 GB
  } else {
    return `${(bytes / (1024 * 1024 * 1024)).toFixed(2)} GB`; // GB and above
  }
};
