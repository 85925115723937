/* eslint-disable */
import React from 'react';
import compose from 'lodash/fp/compose';
import {
  withTranslation,
  withCustomRouter,
  withUserPreferences,
  withWorkAbsenceInfo,
} from 'components/hoc';
import MyDataContentTemplate from 'components/Dashboard/MyData/common/MyDataContentTemplate';
import LottieLoader from 'components/common/LottieLoader';

const WorkAbsence = (props) => {
  const mapFields = () => {
    const { workAbsenceInfo, fd, trObj } = props;

    return workAbsenceInfo.data.map((data) => {
      return {
        ...data,
        person: data?.person_detail?.first_name + ' ' + data?.person_detail?.last_name,
        valid_from: fd(data.valid_from),
        valid_to: fd(data.valid_to),
        organisation: data?.organisation_detail?.name,
        industry: trObj(data?.organisation_detail?.industry_type_detail),
        absence_type: trObj(data?.absence_type_detail),
      };
    });
  };

  const { loaded, deleteBulkWorkAbsence, gotoCreatePath } = props;

  // Add filterableColumns configuration
  const filterableColumns = [
 
    {
      columnId: 'person',
      columnDataType: 'text',
    },
    {
        columnId: 'organisation',
        columnDataType: 'text',
      },
    {
      columnId: 'valid_from',
      columnDataType: 'date',
    },
    {
      columnId: 'valid_to',
      columnDataType: 'date',
    },
    {
        columnId: 'absence_type',
        columnDataType: 'text',
    },
      {
        columnId: 'comment',
        columnDataType: 'text',
      },
  ];

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  return (
    <MyDataContentTemplate
      tableName="work_absence"
      columns={[]}
      data={mapFields()}
      handleCreateForm={gotoCreatePath}
      bulkDelete={deleteBulkWorkAbsence}
      filterableColumns={filterableColumns}
    />
  );
};

export default compose(
  withTranslation,
  withWorkAbsenceInfo,
  withCustomRouter,
  withUserPreferences,
)(WorkAbsence);
