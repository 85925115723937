import React from 'react';
import compose from 'lodash/fp/compose';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import {
  withPersonalInfo,
  withCustomRouter,
  withTranslation,
  withOrganisation,
  withMsgInfo,
  withTravelInfo,
  withTravelType,
  withMeansOfTransport,
  withCountry,
  withAddressInfo,
} from 'components/hoc';
import {
  Select,
  Input,
  Form,
  InputDatePicker,
  ButtonLink,
  ColumnalSelect,
} from 'components/common/FormItems';

const Wrapper = styled.div``;

const DualInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    min-width: 150px;
    flex: 1;
    margin-right: 1rem;

    :last-child {
      margin-right: 0px;
    }
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const StyledSelect = styled(Select)`
  padding-right: 2rem;
`;

const StyledInput = styled(Input)`
  padding-right: 1.5rem;
`;

const StyledInputDatePicker = styled(InputDatePicker)`
  padding-right: 1.7rem;
`;

const StyledColumnalSelect = styled(ColumnalSelect)`
  margin-right: 2rem;
`;

const TravelFormSchema = {
  description: {
    validation: 'required',
  },

  type_of_travel: {
    validation: 'required',
  },

  person: {
    validation: 'required',
  },

  valid_from: {
    validation: 'required',
  },

  valid_to: {
    validation: 'required',
  },
};

const addressLabels = [
  { _country: 'Country' },
  { _city: 'City' },
  { _street: 'Street' },
];

const TravelForm = (props) => {
  const {
    tr,
    trObj,
    basePath,
    routeParams,
    updateTravel,
    addTravel,
    getTravelById,
    setMsgInfo,
    travelTypeLocaleOptions,
    meansOfTransportLocaleOptions,
    countryLocaleOptions,
  } = props;

  const [formData, setFormData] = React.useState({});
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);

  const { id } = routeParams;
  const history = useHistory();
  const dispatch = useDispatch();

  const getAddressInfo = () => {
    return props.addressInfo.data.map(
      ({
        country_detail,
        city_detail,
        street_name,
        street_no,
        street_no_suffix,
        ...rest
      }) => ({
        ...rest,
        _country: trObj(country_detail),
        _city: trObj(city_detail),
        _street_name: street_name,
        _street_no: street_no,
        _street_no_suffix: street_no_suffix,
      }),
    );
  };

  const fetchData = async () => {
    if (id) {
      try {
        setFormLoading(true);
        const data = await getTravelById(id);
        setFormData({ ...data });
        setFormLoading(false);
      } catch (e) {
        setFormData({});
        setFormLoading(false);
      }
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const onChange = (e) => {
    if (formSubmitting) return;
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    try {
      let response;
      setFormSubmitting(true);
      if (id) {
        response = await updateTravel(id, formData);
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${tr('travel updated successfully')}`],
          }),
        );
      } else {
        response = await addTravel(formData);
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${tr('travel added successfully')}`],
          }),
        );
      }
      setFormSubmitting(false);

      history.push(basePath);
      window.scrollTo(0, 0);

      if (response?.response?.status >= 400) return;
      return;
    } catch (e) {
      setFormSubmitting(false);
      return;
    }
  };

  return (
    <Wrapper className="form-wrapper">
      <div className="flex">
        <StyledButtonLink
          to={basePath}
          className="option-btn"
          icon="angle double left"
          name="All Entries"
        />
      </div>
      <div className="row">
        <div
          className="col-7 ml-10 mt-30 container"
          style={{
            position: 'relative',
            background: 'var(--admincat-color-grey-1)',
          }}
        >
          <br />

          <Form
            onSubmit={onSubmit}
            formData={formData}
            isLoading={formSubmitting}
            loadingData={formLoading}
            validationSchema={TravelFormSchema}
            title="Travel"
          >
            <StyledInput
              placeholder={tr('Enter Description')}
              label={tr('Description')}
              value={formData?.description}
              name="description"
              onChange={onChange}
              showRequiredAsterisk
            />
            <StyledSelect
              optionDefaultValue={tr('choose travel type')}
              label={tr('type of travel')}
              value={formData?.type_of_travel}
              name="type_of_travel"
              onChange={onChange}
              localeOptions={travelTypeLocaleOptions}
              deselectable
              sort={true}
              showRequiredAsterisk
            />
            <StyledSelect
              optionDefaultValue={tr('select person')}
              label={tr('person')}
              value={formData?.person}
              name="person"
              onChange={onChange}
              options={props.personalInfoOptions}
              deselectable
              sort={false}
              showRequiredAsterisk
            />
            <StyledSelect
              optionDefaultValue={tr('choose means of transportation')}
              label={tr('means of transportation')}
              value={formData?.main_mot}
              name="main_mot"
              onChange={onChange}
              localeOptions={meansOfTransportLocaleOptions}
              deselectable
              sort={true}
            />
            <StyledSelect
              optionDefaultValue={tr('Choose Country')}
              label={tr('country')}
              value={formData?.country}
              name="country"
              onChange={onChange}
              localeOptions={countryLocaleOptions}
              deselectable
              sort={true}
            />
            <StyledColumnalSelect
              optionDefaultValue={tr('choose address')}
              onChange={onChange}
              value={formData?.address || ''}
              name={tr('address')}
              label={tr('sddress')}
              labels={addressLabels}
              options={getAddressInfo()}
            />
            <DualInputWrapper>
              <InputDatePicker
                label="Valid from"
                value={formData.valid_from}
                name="valid_from"
                onChange={onChange}
                showRequiredAsterisk
              />
              <StyledInputDatePicker
                className="ml-sm-1 mr-md-1"
                label="Valid to"
                value={formData.valid_to}
                name="valid_to"
                onChange={onChange}
                showRequiredAsterisk
              />
            </DualInputWrapper>
          </Form>
        </div>
      </div>
    </Wrapper>
  );
};

export default compose(
  withPersonalInfo,
  withCustomRouter,
  withTranslation,
  withTravelInfo,
  withOrganisation,
  withMsgInfo,
  withTravelType,
  withMeansOfTransport,
  withCountry,
  withAddressInfo,
)(TravelForm);
