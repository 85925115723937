import { createSelector } from 'reselect';

export const removeOwnerUtil = (owners, removeIndex) => {
  let lastItemRemoved = owners.length - 1 === removeIndex;
  let newOwners = owners
    .filter((_, filterIndex) => filterIndex !== removeIndex)
    .map((owner, index) => {
      let valIndex = index;
      if (!lastItemRemoved && removeIndex === index) {
        valIndex += 1;
      }
      return {
        [`owner-${index}`]: owner[`owner-${valIndex}`],
        [`valid_from-${index}`]: owner[`valid_from-${valIndex}`],
        [`valid_to-${index}`]: owner[`valid_to-${valIndex}`],
      };
    });

  let errorFields = newOwners.map(() => ({}));
  return {
    newOwners,
    errorFields,
  };
};

const getBankBICState = (state) => state.bankBIC;

export const getBankBICData = createSelector(
  [getBankBICState],
  (bankBIC) => ({
    org_name: bankBIC.data ? bankBIC.data.org_name : '',
    status: bankBIC.status,
  })
);

export const addOwnerUtil = (owners) => {
  const nextIndex = owners.length;
  const newOwners = [
    ...owners,
    {
      [`owner-${nextIndex}`]: '',
      [`valid_from-${nextIndex}`]: '1990-10-03',
      [`valid_to-${nextIndex}`]: '9999-12-31',
    },
  ];
  return newOwners;
};

export const accountDetailsRules = () => ({
  BIC: 'required|min:5|max:12',
  account_no: 'required|length:11',
  access_type: 'required',
  account_type: 'required',
  currency: 'required',
});

export const getOwnerValidation = (owners) => {
  let rules = {};
  owners.forEach((item, i) => {
    rules[`owner-${i}`] = 'required';
  });
  return rules;
};

// export const initialStateData = {
//   step: 1,
//   mode: 'create',
//   accountDetails: {
//     BIC: '',
//     account_no: '',
//     IBAN: '',
//     account_type: '',
//     access_type: '',
//     account_ref: '',
//     currency: '',
//   },
//   errors: { accountDetails: {}, owners: [{}] },
//   owners: [
//     {
//       'owner-0': '',
//       'valid_from-0': '',
//       'valid_to-0': '9999-12-31',
//     },
//   ],
// };

export const initialStateData = {
  accountDetails: {
    BIC: '',
    account_no: '',
    IBAN: '',
    account_type: '',
    access_type: '',
    account_ref: '',
    currency: '',
    valid_from: '',
    valid_to: '9999-12-31',
  },
  errors: { accountDetails: {}, owners: [{}] },
  owners: [
    {
      'owner-0': '',
      'valid_from-0': '1990-10-03',
      'valid_to-0': '9999-12-31',
    },
  ],
};

// export const initialState = {
//   step: 1,
//   // mode: "create",
//   accountDetails: {
//     // BIC: '',
//     // account_no: '',
//     // IBAN: '',
//     // account_type: '',
//     // access_type: '',
//     // account_ref: '',
//     // currency: '',
//   },
//   account_type: '',
//   access_type: '',
//   BIC: '',
//   account_no: '',
//   currency: '',
//   account_ref: '',
//   IBAN: '',
//   errors: { accountDetails: {}, owners: [{}] },
//   // valid_from: '',
//   // valid_to: '9999-12-31',
//   owners: [
//     {
//       'owner-0': '',
//       'valid_from-0': '1990-10-03',
//       'valid_to-0': '9999-12-31',
//     },
//   ],
// };

export const isActive = (valid_from, valid_to) => {
  let date_today = Date.now();
  let date_from = new Date(valid_from).getTime();
  let date_to = new Date(valid_to);
  return date_from < date_today && date_to > date_today;
};

export const getReferenceAccountData = (bankAccounts) => {
  return bankAccounts
    .filter((account) => account.account_type === 'checkacc')
    .map((item) => ({
      id: Number.parseInt(item.id),
      account_no: item.account_no,
      BIC: item.BIC_data.BIC,
      org_name: `${item.BIC_data.org_name.substring(0, 10)}...`,
    }));
};

export const updateFormInitialValue = (data) => {
  if (!data.BIC_data) return initialStateData;
  const {
    BIC_data,
    account_no,
    IBAN,
    account_type,
    access_type,
    account_ref,
    currency,
    owners,

    // valid_from,
    // valid_to,
  } = data;

  const mapOwnerData = owners.map((item, index) => ({
    [`owner-${index}`]: item.owner_data.owner,
    [`valid_from-${index}`]: item.valid_from,
    [`valid_to-${index}`]: item.valid_to,
  }));

  return {
    accountDetails: {
      account_no,
      IBAN,
      account_type,
      access_type,
      account_ref,
      currency,
      BIC: BIC_data.BIC,
    },
    // account_no,
    // IBAN,
    // account_type,
    // access_type,
    // account_ref,
    // currency,
    // valid_from,
    // valid_to,
    // BIC: BIC_data.BIC,
    owners: mapOwnerData,
    errors: {
      owners: Array(mapOwnerData.length).fill({}),
    },
  };
};