import { BaseActionClass } from '../common/BaseActionClass';
import { TRAVEL } from '../types';

const path = '/dashboard/travel';
const name = 'Travel';

class TravelInfo extends BaseActionClass {
  constructor() {
    super(path, TRAVEL, name);
  }

  addTravel = this._add;

  updateTravel = this._update;

  deleteTravelById = this._deleteById;

  getTravel = this._getAll;

  getTravelById = this._getById;

  deleteBulkTravel = this._deleteBulk;
}

export const {
  addTravel,
  updateTravel,
  deleteTravelById,
  getTravelById,
  getTravel,
  deleteBulkTravel,
} = new TravelInfo();
