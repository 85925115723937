/* eslint-disable */
import React from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import {
  withPersonalInfo,
  withCustomRouter,
  withTranslation,
  withOrganisation,
  withMsgInfo,
} from 'components/hoc';
import {
  Select,
  // Input,
  Form,
  InputDatePicker,
  ButtonLink,
  // ColumnalSelect,
} from 'components/common/FormItems';
import { withMembershipInfo } from 'components/hoc/MembershipInfo';
import { withIndustryType, withProfession } from 'components/hoc/Preload';
import { useOrgIndicator } from '../../../../../hooks/useOrgIndicator';
import { ORG_INDICATOR_TYPES } from '../../../../../constants';



const Wrapper = styled.div`
  height: 80vh;
`;

const DualSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    min-width: 200px;
    flex: 1;
    margin-right: 10px;

    :last-child {
      margin-right: 0px;
    }
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const StyledSelect = styled(Select)`
  padding-right: 2rem;
`;

const StyledInputDatePicker = styled(InputDatePicker)`
  padding-right: 2rem;
`;


// const searchById = (term, listItems) => {
//   for (const item of listItems){
//     if (term == item?.id){
//       return item;
//     }
//   }
//   return null;
// }

const memberShipFormSchema = {
  person: {
    validation: 'required',
  },
  organisation: {
    validation: 'required',
  },
  valid_from: {
    validation: 'required',
  },
  // valid_to: {
  //   validation: 'required',
  // },
};

const MembershipForm = (props) => {
  const {
    addMembershipInfo,
    updateMembershipInfo,
    getMembershipInfoById,
    routeParams,
    setMsgInfo,
  } = props;

  const { id } = routeParams;
  const history = useHistory();

  const [formData, setFormData] = React.useState({ valid_to: '9999-12-31' });
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);
  const { handleOrgIndicatorChange, organisationOrPersonOptions } = useOrgIndicator({
    defaultOrgIndicator: ORG_INDICATOR_TYPES.ORG,
  });

  const { tr, basePath, organisation } = props;
  const favorites = organisation?.favorites || [];

  const fetchData = async () => {
    if (id) {
      try {
        setFormLoading(true);
        const data = await getMembershipInfoById(id);
        setFormData({ ...data });
        setFormLoading(false);
      } catch (e) {
        setFormData({});
        setFormLoading(false);
      }
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const onChange = (e) => {
    if (formSubmitting) return;
    const { value, name } = e.target;
    if (name == 'organisation') {
      const nValue = favorites.find((favorite) => favorite.id == value);
      setFormData({ ...formData, [name]: nValue?.organisation });
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    try {
      let response;
      setFormSubmitting(true);
  
      if (id) {
        response = await updateMembershipInfo(id, formData);
        setMsgInfo({
          success: true,
          msg: [`${tr('Membership updated successfully')}`],
        });
      } else {
        response = await addMembershipInfo(formData);
        setMsgInfo({
          success: true,
          msg: [`${tr('Membership added successfully')}`],
        });
      }
      setFormSubmitting(false);
      history.push(basePath);
      window.scrollTo(0, 0); 
      // if an error exist, stay on the page
      if (response?.response?.status >= 400) return;

      // if (!id) {
      //     history.push(basePath);
      //     return;
      // }
      return;
    } catch (e) {
      setFormSubmitting(false);
      return;
    }
  };

  return (
    <Wrapper className="form-wrapper">
    <div className="flex">
      <StyledButtonLink
        to={basePath}
        className="option-btn"
        icon="angle double left"
        name="All Entries"
      />
    </div>
    <div className="row">
      <div
        className="col-7 ml-10 mt-30 container"
        style={{
          position: 'relative',
          background: 'var(--admincat-color-grey-1)',
        }}
      >
        <br />
          <Form
            onSubmit={onSubmit}
            formData={formData}
            isLoading={formSubmitting}
            loadingData={formLoading}
            validationSchema={memberShipFormSchema}
            title="Membership"
          >
            <StyledSelect
              optionDefaultValue={tr('Choose Person')}
              label={tr('Choose Person')}
              value={formData?.person || ''}
              name="person"
              onChange={onChange}
              options={props.personalInfoOptions}
              deselectable
              sort={false}
              showRequiredAsterisk
            />

            <StyledSelect
              optionDefaultValue={tr('Choose Organisation')}
              label={tr('Organisation')}
              // value={formData?.organisation || ''}
              value={
                favorites.find(
                  (favorite) => favorite.organisation == formData?.organisation,
                )?.id
              }
              name="organisation"
              onChange={onChange}
              localeOptions={organisationOrPersonOptions}
              deselectable
              sort={false}
              showRequiredAsterisk
            />

            <DualSelectWrapper>
              <StyledInputDatePicker
                label="Valid From"
                value={formData?.valid_from || ''}
                name="valid_from"
                onChange={onChange}
                showRequiredAsterisk
              />
              <StyledInputDatePicker
                label="Valid To"
                value={formData?.valid_to || ''}
                name="valid_to"
                onChange={onChange}
                // showRequiredAsterisk
              />
            </DualSelectWrapper>
          </Form>
        </div>
      </div>
    </Wrapper>
  );
};

export default compose(
  withPersonalInfo,
  withCustomRouter,
  withTranslation,
  withMembershipInfo,
  withProfession,
  withIndustryType,
  withOrganisation,
  withMsgInfo,
)(MembershipForm);
