import React, { memo, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import compose from 'lodash/fp/compose';
import ListDocumentLottie from 'lottie-react';
import { documentSlice } from 'rootStateHandler/selectors';
import animatedLoading from 'assets/lottie/admit-cat-loader.json';
import { withTranslation } from '../../../hoc';
import { editIcon, deleteIcon, viewIcon } from '../../../../libs/icon';
import EmptyState from 'components/common/EmptyState';
import './listDocuments.css';

// Configure PDF.js worker once
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Utility functions
const getFileExtension = (filename) => {
  const parts = filename.split('.');
  return parts.length > 1 ? parts.pop() || '' : '';
};

const isImageFile = (filename) => {
  const fileExt = getFileExtension(filename);
  const validImageExts = ['gif', 'png', 'jpg', 'jpeg'];
  return validImageExts.includes(fileExt.toLowerCase());
};

const truncateString = (input_string, string_length) => {
  if (!input_string || typeof input_string !== 'string') return '';

  if (input_string.length >= string_length) {
    const ext = getFileExtension(input_string);
    return `${input_string.substring(0, string_length)}...${ext}`;
  }
  return input_string;
};

// Loading component
const LoadingState = memo(({ tr }) => (
  <div className="lottie_loader_document">
    <ListDocumentLottie loop autoplay animationData={animatedLoading} />
    <div className="loading_text">{tr('Loading...')}</div>
  </div>
));

// Empty state component
const EmptyStateWrapper = memo(({ text }) => (
  <div className="empty_state_container">
    <EmptyState text={text} hasButton={false} />
  </div>
));

// Lazy loaded image component
const LazyImage = memo(({ src, alt, className, width, height }) => {
  return (
    <div style={{ width, height }}>
      <LazyLoadImage
        alt={alt}
        height={height}
        src={src} // use normal <img> attributes as props
        width={width}
        className={className}
      />
    </div>
  );
});

// PDF preview component
const PDFPreview = memo(({ file }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  const handleError = (error) => {
    console.error('PDF loading error:', error);
    setError(error);
  };

  return (
    <div className="pdf-wrapper">
      <Document
        file={file}
        loading={null}
        onLoadSuccess={() => setIsLoaded(true)}
        onLoadError={handleError}
      >
        {isLoaded && (
          <Page
            pageNumber={1}
            width={62}
            className="pdf-page"
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        )}
      </Document>
      {error && <div className="pdf-error">Failed to load PDF</div>}
    </div>
  );
});

// Document preview item component
const DocumentPreviewItem = memo(
  ({ document, isSelected, onSelect, onEdit, onDelete }) => {
    const handleClick = useCallback(() => {
      onSelect(document.id);
    }, [document.id, onSelect]);

    const handleEditClick = useCallback(
      (e) => {
        e.stopPropagation();
        onEdit();
      },
      [onEdit],
    );

    const handleDeleteClick = useCallback(
      (e) => {
        e.stopPropagation();
        onDelete();
      },
      [onDelete],
    );

    const downloadUrl = useMemo(
      () =>
        `https://download.admincat.net/media/documents/${document.filename
          .split(' ')
          .join('_')}`,
      [document.filename],
    );

    return (
      <div className="document_preview_container">
        <div
          className="document_preview"
          aria-selected={isSelected}
          onClick={handleClick}
        >
          {isImageFile(document.filename) ? (
            <LazyImage
              src={document.file}
              alt="document"
              className="preview-content"
              width={62}
              height={85}
            />
          ) : (
            <PDFPreview file={document.file} />
          )}

          {document.id && (
            <div className={isSelected ? 'popIcon' : 'hideIcon'}>
              <span onClick={handleEditClick}>{editIcon}</span>
              <span>
                <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
                  {viewIcon}
                </a>
              </span>
              <span onClick={handleDeleteClick}>{deleteIcon}</span>
            </div>
          )}
        </div>

        <p
          className="documentTitle"
          style={{ color: 'var(--admincat-color-grey-2)' }}
        >
          {truncateString(document.filename, 10)}
        </p>
      </div>
    );
  },
);

// Filtered documents component
const FilteredDocuments = memo(
  ({ documents, loading, selectedId, onSelect, onEdit, onDelete, onView, tr }) => {
    if (loading) return <LoadingState tr={tr} />;
    if (!documents?.length) return <EmptyStateWrapper text={tr('No result')} />;

    // CloudFront base URL for document snippets
    //const cloudFrontBaseUrl = "https://d1c3xb6kky5hcp.cloudfront.net/media/";

    return (
      <div className="list_documents_container">
        {documents.map((document) => (
          <div key={document?.id} className="document_preview_container">
            <div
              className="document_preview"
              aria-selected={selectedId === document?.id}
              onClick={() => onSelect(document?.id)}
            >
              {document?.snippet ? (
                <LazyImage
                  src={document.snippet}
                  // src={`${cloudFrontBaseUrl}${document.snippet}`}
                  alt="snippet"
                  width={85}
                  height={118}
                />
              ) : (
                <PDFPreview file={document.file} />
              )}

              <div className={selectedId === document.id ? 'popIcon' : 'hideIcon'}>
                <span onClick={onEdit}>{editIcon}</span>
                <span onClick={onView}>{viewIcon}</span>
                <span onClick={onDelete}>{deleteIcon}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  },
);

// Document previews component
const DocumentPreviews = memo(
  ({ documents, loading, selectedId, onSelect, onEdit, onDelete, tr }) => {
    if (documents?.length === 0) {
      return <EmptyStateWrapper text={tr('No document uploaded yet!')} />;
    }

    if (loading) {
      return <LoadingState tr={tr} />;
    }

    return (
      <div className="list_documents_container">
        {documents?.map((document) => (
          <DocumentPreviewItem
            key={document?.id}
            document={document}
            isSelected={selectedId === document?.id}
            onSelect={onSelect}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ))}
      </div>
    );
  },
);

// Main component
const ListDocuments = (props) => {
  const {
    handleDisplayInformation,
    isSearching,
    document_id,
    handleDelete,
    handleEdit,
    handleViewDocument,
    tr,
  } = props;

  const { filtere_documents, loading, list_documents, search_result, isFiltering } =
    useSelector(documentSlice);

  const filteredDocuments = useMemo(
    () => [...filtere_documents],
    [filtere_documents],
  );

  // Memoized search results section
  const DocumentSearchPreviews = useMemo(() => {
    if (loading) return <LoadingState tr={tr} />;

    if (!search_result?.length) {
      return <EmptyStateWrapper text={tr('No result was found...')} />;
    }

    return (
      <div className="filtered_documents_container">
        <p>
          {tr('Results found')}: {search_result.length} {tr('result(s)')}
        </p>

        {search_result.map((document) => (
          <div key={document?.id} className="filtered_documents_wrapper">
            <div
              className="filtered_document_preview"
              onClick={() => handleDisplayInformation(document.id)}
            >
              {document?.snippet ? (
                <LazyImage
                  src={document.snippet}
                  alt="snippet"
                  width={85}
                  height={118}
                />
              ) : (
                <PDFPreview file={document.file} />
              )}
            </div>

            <div className="filtered_preview_info">
              <p>{truncateString(document?.filename, 30)}</p>
              <p>{truncateString(document?.description, 30)}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }, [loading, search_result, tr, handleDisplayInformation]);

  return (
    <>
      {isFiltering && (
        <FilteredDocuments
          documents={filteredDocuments}
          loading={loading}
          selectedId={document_id}
          onSelect={handleDisplayInformation}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onView={handleViewDocument}
          tr={tr}
        />
      )}

      {!isSearching && !isFiltering && (
        <DocumentPreviews
          documents={list_documents}
          loading={loading}
          selectedId={document_id}
          onSelect={handleDisplayInformation}
          onEdit={handleEdit}
          onDelete={handleDelete}
          tr={tr}
        />
      )}

      {isSearching && DocumentSearchPreviews}
    </>
  );
};

export default compose(withTranslation)(ListDocuments);
