import React from 'react';
import compose from 'lodash/fp/compose';
import styled, { css } from 'styled-components';
import { Input } from 'components/common/FormItems';
import Loader from 'components/common/Loader';
import { withTranslation } from '../../hoc';
import './index.css';

const StyledLoader = styled(Loader)`
  margin-left: 20px;
  height: 30px;
  position: relative;
  top: -6px;
`;

const Modal = (props) => {
  const { tr } = props;

  return props.toggleModal ? (
    <div className="success-modal position-fixed">
      <div className="modal-inner full-width full-height flex">
        <div className="_modal-body margin-auto">
          {props.hasInput && (
            <Input
              label={props.label}
              name={props.name}
              id={props.name}
              value={props.value}
              placeholder={props.placeholder}
              onChange={props.handleChange}
            />
          )}
          {props.success && (
            <span className="block icon-area text-center">
              <span>&#10003;</span>
            </span>
          )}

          {props.warning && (
            <span className="block warning-icon-area text-center">
              {/* <span>&#10006;</span> */}
              <span>&#33;</span>
            </span>
          )}

          {props.title && (
            <span className="block title text-center">{tr(`${props.title}`)}</span>
          )}

          {props.message && (
            <span className="block message text-center">
              {tr(`${props.message}`)}
            </span>
          )}

          <div className="flex row-reverse">
            {props.success && (
              <button onClick={props.onClick} className="close-modal cursor-pointer">
                {tr(`${props.buttonTitle}`)}
              </button>
            )}

            {props.warning && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  height: 100,
                }}
              >
                <button
                  onClick={props.handleConfrimDelete}
                  className="close-warning-modal cursor-pointer"
                >
                  {props.isSubmitting ? (
                    <StyledLoader />
                  ) : (
                    tr(`${props.buttonTitle}`)
                  )}
                </button>

                <button
                  onClick={props.handleToggleModal}
                  className="cancel-warning-modal cursor-pointer"
                >
                  {tr('Cancel')}
                </button>
              </div>
            )}

            {props.hasDualBtn && (
              <>
                <button
                  onClick={props.onClick}
                  className="save-input-modal cursor-pointer"
                >
                  {props.isSubmitting ? (
                    <StyledLoader />
                  ) : (
                    tr(`${props.buttonTitle}`)
                  )}
                </button>

                <button
                  onClick={props.handleToggleModal}
                  className="close-input-modal cursor-pointer"
                >
                  {tr('Cancel')}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default compose(withTranslation)(Modal);
