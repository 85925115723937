import React, { Component } from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import API from '../../actions/api';

// Styled components for the UI
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f8f9fa;
`;

const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 500px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #333;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const Message = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  color: #555;
`;

const IconWrapper = styled.div`
  margin-bottom: 1.5rem;
  font-size: 4rem;
  
  &.success {
    color: #28a745;
  }
  
  &.error {
    color: #dc3545;
  }
  
  &.warning {
    color: #ffc107;
  }
`;

const Button = styled.button`
  background-color: #4C81BE;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #3a6491;
  }
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #4C81BE;
  width: 40px;
  height: 40px;
  margin: 20px auto;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

class DisapproveSpaceAdmin extends Component {
  state = {
    status: 'loading', // loading, invalid, error, success
    message: '',
  };

  componentDidMount() {
    // Get language from URL query parameters
    const params = new URLSearchParams(window.location.search);
    const lang = params.get('lang');
    
    // Change language if specified in URL
    if (lang) {
      i18n.changeLanguage(lang);
    }
    
    this.processDisapproval();
  }

  processDisapproval = async () => {
    // Get token from URL query parameters
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (!token) {
      this.setState({
        status: 'invalid',
        message: 'Invalid request. No disapproval token was provided.',
      });
      return;
    }

    try {

       const res = await axios.get(`${API}/space/disapprove_admin_change/?token=${token}`);

      // Simulate API call with a timeout
      setTimeout(() => {
        // For simulation, we'll randomly succeed or fail
        const randomSuccess = Math.random() > 0.3; // 70% success rate for demo
        
        if (randomSuccess) {
          this.setState({
            status: 'success',
            message: 'The space admin request has been successfully disapproved.',
          });
        } else {
          this.setState({
            status: 'error',
            message: 'There was an error processing your request. The token may be invalid or expired.',
          });
        }
      }, 1500); // Simulate network delay
      
      // In the real implementation, we would make an actual API call:
      // const response = await axios.post('/api/v2/disapprove-space-admin', { token });
      // this.setState({
      //   status: 'success',
      //   message: response.data.message || 'The space admin request has been successfully disapproved.',
      // });
      
    } catch (error) {
      this.setState({
        status: 'error',
        message: error.response?.data?.message || 'There was an error processing your request.',
      });
    }
  };

  renderContent = () => {
    const { status, message } = this.state;
    const { t } = this.props;

    switch (status) {
      case 'loading':
        return (
          <>
            <Title>{t('Processing Request')}</Title>
            <LoadingSpinner />
            <Message>{t('Please wait while we process your request...')}</Message>
          </>
        );
      
      case 'invalid':
        return (
          <>
            <IconWrapper className="warning">
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </IconWrapper>
            <Title>{t('Invalid Request')}</Title>
            <Message>{t(message)}</Message>
            <Button onClick={() => window.location.href = '/'}>{t('Return to Home')}</Button>
          </>
        );
      
      case 'error':
        return (
          <>
            <IconWrapper className="error">
              <FontAwesomeIcon icon={faTimesCircle} />
            </IconWrapper>
            <Title>{t('Request Failed')}</Title>
            <Message>{t(message)}</Message>
            <Button onClick={() => window.location.href = '/'}>{t('Return to Home')}</Button>
          </>
        );
      
      case 'success':
        return (
          <>
            <IconWrapper className="success">
              <FontAwesomeIcon icon={faCheckCircle} />
            </IconWrapper>
            <Title>{t('Request Successful')}</Title>
            <Message>{t(message)}</Message>
            <Button onClick={() => window.location.href = '/'}>{t('Return to Home')}</Button>
          </>
        );
      
      default:
        return null;
    }
  };

  render() {
    return (
      <Container>
        <Card>
          {this.renderContent()}
        </Card>
      </Container>
    );
  }
}

export default compose(
  withTranslation()
)(DisapproveSpaceAdmin);