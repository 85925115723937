/* eslint-disable */
import React from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  withPersonalInfo,
  withCustomRouter,
  withTranslation,
  withOrganisation,
  withMsgInfo,
} from 'components/hoc';
import {
  Select,
  Input,
  Form,
  InputDatePicker,
  ButtonLink,
  ColumnalSelect,
} from 'components/common/FormItems';
import { withWorkExperienceInfo } from 'components/hoc/WorkExperienceInfo';
import { withIndustryType, withProfession } from 'components/hoc/Preload';
import { useOrgIndicator } from '../../../../../hooks/useOrgIndicator';
import { ORG_INDICATOR_TYPES } from '../../../../../constants';
import { useFavoriteOrganisation } from 'hooks/useFavoriteOrganisation';

const professionLabels = [
  {
    profession: 'Profession',
  },
];

const getProfessionOptions = (options, trObj) =>
  options.map(({ id, name }) => {
    if (id.includes('X')) {
      return {
        id,
        profession: name,
        header: true,
      };
    }
    return {
      id,
      profession: name,
    };
  });

const Wrapper = styled.div``;

const DualSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    min-width: 200px;
    flex: 1;
    margin-right: 10px;

    :last-child {
      margin-right: 0px;
    }
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;


const StyledSelect = styled(Select)`
  padding-right: 2.5rem;
`;

const StyledInput = styled(Input)`
 padding-right: 2rem;
`;

const StyledInputDatePicker = styled(InputDatePicker)`
padding-right: 2.5rem;
`;

const StyledColumnalSelect = styled(ColumnalSelect)`
  padding-right: 2.5rem;
  ${'' /* height: 200px; */}
`;

const searchById = (term, listItems) => {
  for (const item of listItems) {
    if (term == item?.id) {
      return item;
    }
  }
  return null;
};

const workExperienceFormSchema = {
  person: {
    validation: 'required',
  },
  organisation: {
    validation: 'required',
  },
  profession: {
    validation: 'required',
  },
  title_description: {
    validation: 'required',
  },
  valid_from: {
    validation: 'required',
  },
};

const WorkExperienceForm = (props) => {
  const {
    addWorkExperience,
    updateWorkExperience,
    getWorkExperienceById,
    routeParams,
    trObj,
    tr, 
    basePath,
    setMsgInfo,
  } = props;

  const { id } = routeParams;
  const history = useHistory();
  const dispatch = useDispatch();

  const [formData, setFormData] = React.useState({ valid_to: '9999-12-31' });
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);

  const [selectedIndustry, setSelectedIndustry] = React.useState('');

  const { handleOrgIndicatorChange, organisationOrPersonOptions } = useOrgIndicator({
    defaultOrgIndicator: ORG_INDICATOR_TYPES.ORG,
  });

  const favoriteOrganisation = useFavoriteOrganisation();

  const fetchData = async () => {
    if (id) {
      try {
        setFormLoading(true);
        const data = await getWorkExperienceById(id);
        setFormData({ ...data });
        setFormLoading(false);
      } catch (e) {
        setFormData({});
        setFormLoading(false);
      }
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (formData.organisation) {
      fetchIndustryByOrganisationId(formData.organisation);
    }
  }, [formData.organisation]);

  const fetchIndustryByOrganisationId = (orgId) => {
    const org = searchById(orgId, favoriteOrganisation);
    if (org) {
      const industryType = trObj(org?.organisation_data?.industry_type_detail);
      setSelectedIndustry(industryType);
    } else {
      setSelectedIndustry('');
    }
  };

  // const getIndustryByOrgId = (orgId) => {
  //   const org = searchById(orgId, favoriteOrganisation);
  //   if (org && org.organisation_data) {
  //     return trObj(org.organisation_data.industry_type_detail);
  //   }
  //   return '';
  // };

  const onChange = (e) => {
    if (formSubmitting) return;
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    try {
      let response;
      setFormSubmitting(true);
      if (id) {
        response = await updateWorkExperience(id, formData);
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${tr('Work Experience updated successfully')}`],
          }),
        );
      } else {
        response = await addWorkExperience(formData);
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${tr('Work Experience added successfully')}`],
          }),
        );
      }
      setFormSubmitting(false);

      history.push(basePath);
      window.scrollTo(0, 0);
      // if an error exist, stay on the page
      if (response?.response?.status >= 400) return;
      return;
    } catch (e) {
      setFormSubmitting(false);
      return;
    }
  };

  return (
    <Wrapper className="form-wrapper">
    <div className="flex">
      <StyledButtonLink
        to={basePath}
        className="option-btn"
        icon="angle double left"
        name="All Entries"
      />
    </div>
    <div className="row">
      <div
        className="col-7 ml-10 mt-30 container"
        style={{
          position: 'relative',
          background: 'var(--admincat-color-grey-1)',
        }}
      >
        <br />

          <Form
            onSubmit={onSubmit}
            formData={formData}
            isLoading={formSubmitting}
            loadingData={formLoading}
            validationSchema={workExperienceFormSchema}
            title="Work Experience"
          >
            <StyledSelect
              optionDefaultValue={tr('Choose Person')}
              label={tr('Choose Person')}
              value={formData?.person || ''}
              name="person"
              onChange={onChange}
              options={props.personalInfoOptions}
              deselectable
              sort={false}
              showRequiredAsterisk
            />

            <StyledSelect
              optionDefaultValue={tr('Choose Organisation')}
              label={tr('Organisation')}
              value={formData?.organisation}
              name="organisation"
              onChange={onChange}
              options={favoriteOrganisation}
              deselectable
              sort={false}
              showRequiredAsterisk
            />

            <StyledInput
              placeholder={tr('Industry')}
              label={tr('Industry')}
              value={selectedIndustry}
              name="industry"
              onChange={onChange}
              disabled
            />
            <StyledColumnalSelect
              onChange={onChange}
              value={formData?.profession || ''}
              name="profession"
              label="Profession"
              group
              optionDefaultValue={tr('Choose Profession')}
              labels={professionLabels}
              options={getProfessionOptions(props.professionOptions, trObj)}
              showRequiredAsterisk
              sort={false}
            />
            <StyledInput
              className="mr-md-1"
              value={formData?.title_description || ''}
              onChange={onChange}
              name="title_description"
              id="title_description"
              placeholder="Title description"
              label={tr('Title description')}
              showRequiredAsterisk
            />
            <DualSelectWrapper>
              <InputDatePicker
                label="Valid From"
                value={formData?.valid_from || ''}
                name="valid_from"
                onChange={onChange}
                showRequiredAsterisk
              />
              <StyledInputDatePicker
                label="Valid To"
                value={formData?.valid_to || ''}
                name="valid_to"
                onChange={onChange}
              />
            </DualSelectWrapper>
          </Form>
        </div>
      </div>
    </Wrapper>
  );
};

export default compose(
  withPersonalInfo,
  withCustomRouter,
  withTranslation,
  withWorkExperienceInfo,
  withProfession,
  withIndustryType,
  withOrganisation,
  withMsgInfo,
)(WorkExperienceForm);
