import React from "react";
import { connect } from "react-redux";
import { getAbsenceType } from "actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withAbsenceType = (WrapperComponent) => {
  class WithAbsenceType extends React.Component {
    componentDidMount() {
      const { getAbsenceType } = this.props;
      if (loaded) return;
      loaded = true;
      getAbsenceType();
    }

    getAbsenceTypeOptions = () => undefined

    getAbsenceTypeLocaleOptions = () => {
      const { absenceType } = this.props;
      return absenceType.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          absenceTypeOptions={this.getAbsenceTypeOptions()} 
          absenceTypeLocaleOptions={this.getAbsenceTypeLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ absenceType }) => ({ absenceType })
  const WithAbsenceTypeHOC = connect(mapStateToProps, {
    getAbsenceType,
  })(WithAbsenceType);
  hoistNonReactStatics(WithAbsenceTypeHOC, WrapperComponent)
  return WithAbsenceTypeHOC
}
