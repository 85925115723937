import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from "hoist-non-react-statics";
import * as realEstateInfoActions from "actions/dashboard/realEstateInfo";
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC";

const initialState = {
  loaded: false,
}

export const withRealEstateInfo = (WrappedComponent) => {

  class WithRealEstateInfo extends BaseHOC {

    state = { ...initialState }

    async componentDidMount() {
      const { getRealEstate } = this.props
      if (!this.state.loaded)  {
        await getRealEstate();
        this.setState({...initialState, loaded: true });
      }
    }
    
    deleteRealEstateInfoById = async (data) => {
      const { deleteRealEstateById } = this.props
      await this._deleteById(deleteRealEstateById, data);
    }

    deleteBulkRealEstateInfo = async (data) => {
      const { deleteBulkRealEstate } = this.props
      await this._deleteBulk(deleteBulkRealEstate, data);
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          loaded={this.state.loaded}
          deleteBulkRealEstateInfo={this.deleteBulkRealEstateInfo}
          deleteRealEstateInfoById={this.deleteRealEstateInfoById}
        
        />
      )
    }
  }
  const mapStateToProps = ({ realEstateInfo }) => ({ realEstateInfo })
  const RealEstateInfo = connect(mapStateToProps, realEstateInfoActions)(WithRealEstateInfo);
  hoistNonReactStatics(RealEstateInfo, WrappedComponent)
  return compose(withBaseHOCProps)(RealEstateInfo);
}
