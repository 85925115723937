import { BaseActionClass } from "../common/BaseActionClass"
import { REAL_ESTATE } from '../types'

const path = "/dashboard/real_estate";
const name = "Real Estate";

class RealEstateInfo extends BaseActionClass {

  constructor() { super(path, REAL_ESTATE, name) }

  addRealEstate = this._add;

  updateRealEstate = this._update;

  deleteRealEstateById = this._deleteById;

  getRealEstate = this._getAll;

  getRealEstateById = this._getById;

  deleteBulkRealEstate = this._deleteBulk;
}

export const { 
    addRealEstate,
    updateRealEstate,
    deleteRealEstateById,
    getRealEstateById,
    getRealEstate,
    deleteBulkRealEstate,
} = new RealEstateInfo()

