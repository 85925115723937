import React from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { excludeUnsetField } from 'components/utils/excludeUnsetField';
import {
  withTranslation,
  withPersonalInfo,
  withNationality,
  withCitizenshipInfo,
  withCustomRouter,
  withMsgInfo,
} from '../../../../hoc';
import {
  InputDatePicker,
  ColumnalSelect,
  DualInputWrapper,
  Select,
  Form,
  ButtonLink,
} from '../../../../common/FormItems';
import { useHistory } from 'react-router-dom';

const ValidityLabel = styled(({ className, tr }) => (
  <div className={`form-group ${className}`}>
    <label className="form-label">{tr('Validity Period')}</label>
  </div>
))`
  && {
    margin-top: 1rem;
    margin-bottom: 0;
  }
`;

const Wrapper = styled.div``;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const StyledSelect = styled(Select)`
  padding-right: 2rem;
`;

const StyledColumnalSelect = styled(ColumnalSelect)`
  margin-right: 2rem;
`;

const StyledInputDatePicker = styled(InputDatePicker)`
  padding-right: 2rem;
`;

const personLabels = [
  {
    first_name: 'First Name',
  },
  {
    last_name: 'Last Name',
  },
];

const citizenShipFormSchema = {
  citizen: {
    validation: 'required',
  },
  nationality: {
    validation: 'required',
  },
  valid_from: {
    validation: 'required',
  },
  valid_to: {
    validation: 'required',
  },
};

const CitizenshipForm = (props) => {
  const {
    addCitizenshipInfo,
    updateCitizenshipInfo,
    getCitizenshipInfoById,
    routeParams,
    // gotoDetailPath,
    basePath,
    tr,
    setMsgInfo,
  } = props;

  const history = useHistory();
  const { id: citizenshipId } = routeParams;

  const [formData, setFormData] = React.useState({});
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);

  const fetchData = async () => {
    if (citizenshipId) {
      try {
        setFormLoading(true);
        const data = await getCitizenshipInfoById(citizenshipId);
        setFormData({ ...data });
        setFormLoading(false);
      } catch (e) {
        setFormData({});
        setFormLoading(false);
      }
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const onChange = (e) => {
    if (formSubmitting) return;
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    if (formSubmitting) return;
    let payload = excludeUnsetField(formData);

    try {
      setFormSubmitting(true);
      let citizenship = {};
      if (!formData.id) {
        citizenship = await addCitizenshipInfo(payload);
        setMsgInfo({
          success: true,
          msg: [`${tr('Citizenship added successfully')}`],
        });
      } else {
        citizenship = await updateCitizenshipInfo(formData.id, payload);
        setMsgInfo({
          success: true,
          msg: [`${tr('Citizenship updated successfully')}`],
        });
      }
      history.push(basePath);
      window.scrollTo(0, 0);
    } catch (e) {
    } finally {
      setFormSubmitting(false);
    }
  };

  return (
    <Wrapper className="form-wrapper">
    <div className="flex">
      <StyledButtonLink
        to={basePath}
        className="option-btn"
        icon="angle double left"
        name="All Entries"
      />
    </div>
    <div className="row">
      <div
        className="col-7 ml-10 mt-30 container"
        style={{
          position: 'relative',
          background: 'var(--admincat-color-grey-1)',
        }}
      >
        <br />

          <Form
            onSubmit={onSubmit}
            formData={formData}
            isLoading={formSubmitting}
            loadingData={formLoading}
            validationSchema={citizenShipFormSchema}
            title="Citizenship"
          >
            <StyledColumnalSelect
              onChange={onChange}
              value={formData?.citizen }
              name="citizen"
              label="Citizen"
              showRequiredAsterisk
              optionDefaultValue="Choose Citizen"
              labels={personLabels}
              options={props.personalInfo.data}
            />
{/* 
             <Select
                optionDefaultValue={tr('Choose Citizen')}
                labels={personLabels}
                // label={tr('Citizen')}
                value={formData?.citizen || ''}
                name="citizen"
                onChange={onChange}
                options={props.personalInfo.data}
                deselectable
                sort={false}
                showRequiredAsterisk
              /> */}

            <StyledSelect
              onChange={onChange}
              value={formData?.nationality }
              name="nationality"
              label="Nationality"
              showRequiredAsterisk
              optionDefaultValue="Choose Nationality"
              localeOptions={props.nationalityLocaleOptions}
            />
            <ValidityLabel tr={tr} />
            <DualInputWrapper>
              <InputDatePicker
                label="Valid From"
                value={formData?.valid_from }
                name="valid_from"
                onChange={onChange}
                showRequiredAsterisk
              />
              <StyledInputDatePicker
                label="Valid To"
                value={formData?.valid_to}
                name="valid_to"
                onChange={onChange}
                showRequiredAsterisk
              />
            </DualInputWrapper>
          </Form>
        </div>
      </div>
    </Wrapper>
  );
};

export default compose(
  withTranslation,
  withPersonalInfo,
  withNationality,
  withCitizenshipInfo,
  withCustomRouter,
  withMsgInfo,
)(CitizenshipForm);
