import React, { useEffect } from 'react';
import Lottie from 'lottie-react';
import compose from 'lodash/fp/compose';
import { useSelector, useDispatch } from 'react-redux';

import { convertToBytes, MAX_STORAGE_SIZE } from 'utils';
import { withTranslation, withAccount } from 'components/hoc';
import { dashboardSlice } from 'rootStateHandler/selectors';
import animatedLoading from 'assets/lottie/admit-cat-loader.json';
import withFileSize from 'components/hoc/FileSizeChecker/File';
import {
  getNotificationsThunk,
  dashboardSummaryThunk,
} from 'components/Dashboard/thunks';
import Grids from './Elements/Grids';
import styles from './dashboard.module.css';
import { setMsgInfo } from 'actions';

const Dashboard = (props) => {
  const { tr, willSubscriptionEndInTwoWeeks } = props;

  const { notifications, dashboard_summary, loading } = useSelector(dashboardSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    getStorageSpace();
  }, [dashboard_summary?.total_storage_in_use]);

  const getStorageSpace = () => {
    const used_storage_space = convertToBytes(
      dashboard_summary?.total_storage_in_use || '0KB',
    );
    const percentage = (used_storage_space / MAX_STORAGE_SIZE) * 100;
    const isAllMostFull = percentage > 90 && percentage < 100;
    const isOverLimit = percentage >= 100;

    if (isOverLimit) {
      dispatch(
        setMsgInfo({
          danger: true,
          msg: [tr(`storage exceeded 100 mb. please clean up and remove files.`)],
        }),
      );
    }

    if (isAllMostFull) {
      dispatch(
        setMsgInfo({
          warning: true,
          msg: [tr(`storage limit of 100 mb almost reached.`)],
        }),
      );
    }
  };

  // 2 weeks notification warning only on dashboard
  useEffect(() => {
    // Check if payment banner has been shown in current session
    const hasShownPaymentBanner = localStorage.getItem('paymentBannerShown');

    if (!hasShownPaymentBanner && willSubscriptionEndInTwoWeeks) {
      // Note: msg array cannot be empty for the notification component to show
      // This is a placeholder message that won't be displayed when showPaymentBanner is true
      dispatch(
        setMsgInfo({
          showPaymentBanner: true,
          msg: [tr('_placeholder_msg_')], // Required non-empty message, won't be displayed
        }),
      );
      // Mark banner as shown for this session, wont be shown again till user logs out
      localStorage.setItem('paymentBannerShown', 'true');
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getNotificationsThunk());
    dispatch(dashboardSummaryThunk());
  }, [dashboard_summary?.user_space]);

  return (
    <>
      {loading || !Boolean(dashboard_summary?.user_space) ? (
        <div className={styles['lottie_loader']}>
          <Lottie loop autoplay animationData={animatedLoading} />
        </div>
      ) : (
        <div>
          <Grids
            storageCounter={dashboard_summary?.total_storage_in_use}
            dashboard_summary={dashboard_summary}
            notifications={notifications}
          />
        </div>
      )}
    </>
  );
};

export default compose(withFileSize, withAccount, withTranslation)(Dashboard);
