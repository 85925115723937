export const transFormInputData = (data, bicId) => {
  const { accountDetails, owners } = data;
  
  const ownerArray = owners.map((owner, i) => ({
    valid_from: owner[`valid_from-${i}`],
    valid_to: owner[`valid_to-${i}`],
    owner: owner[`owner-${i}`],
  }));
  
  // Don't overwrite accountDetails dates with owner dates
  const bankAccount = { ...accountDetails };
  
  return {
    ...bankAccount,
    BIC: bicId,
    owners: ownerArray,
  };
};


// export const transFormInputData = (data, bicId) => {
//   const { accountDetails, owners } = data;

//   const ownerArray = owners.map((owner, i) => ({
//     valid_from: owner[`valid_from-${i}`],
//     valid_to: owner[`valid_to-${i}`],
//     owner: owner[`owner-${i}`],
//   }));
//   const { valid_from, valid_to } = ownerArray[0];
//   const banKAccount = { ...accountDetails, valid_to, valid_from };
//   return {
//     ...banKAccount,
//     BIC: bicId,
//     owners: ownerArray,
//   };
// };


