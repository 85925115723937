import React from 'react';
import { connect } from 'react-redux';
import { getTravelType } from 'actions';
import hoistNonReactStatics from 'hoist-non-react-statics';

let loaded = false;

export const withTravelType = (WrapperComponent) => {
  class WithTravelType extends React.Component {
    componentDidMount() {
      const { getTravelType } = this.props;
      if (loaded) return;
      loaded = true;
      getTravelType();
    }

    getTravelTypeOptions = () => undefined;

    getTravelTypeLocaleOptions = () => {
      const { travelType } = this.props;
      return travelType.map(({ created_at, ...rest }) => ({ ...rest }));
    };
    render() {
      return (
        <WrapperComponent
          travelTypeOptions={this.getTravelTypeOptions()}
          travelTypeLocaleOptions={this.getTravelTypeLocaleOptions()}
          {...this.props}
        />
      );
    }
  }
  const mapStateToProps = ({ travelType }) => ({ travelType });
  const WithTravelTypeHOC = connect(mapStateToProps, {
    getTravelType,
  })(WithTravelType);
  hoistNonReactStatics(WithTravelTypeHOC, WrapperComponent);
  return WithTravelTypeHOC;
};
