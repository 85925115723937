import React, { useState, useEffect } from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
// import Validator from 'validatorjs';
import {
  withContactType,
  withContactInfo,
  withPersonalInfo,
  withCountry,
  withAddressInfo,
  withTranslation,
  withCustomRouter,
  withMsgInfo,
} from 'components/hoc';
import {
  Select,
  SelectCountryCode,
  Input,
  ColumnalSelect,
  Switchery,
  Form,
  ButtonLink,
} from 'components/common/FormItems';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { excludeUnsetField } from 'components/utils/excludeUnsetField';
// import { contactValidationConstraints } from 'helpers/validation';


const Wrapper = styled.div``;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const StyledSelect = styled(Select)`
  padding-right: 2rem;
`;

const StyledInput = styled(Input)`
  padding-right: 1.6rem;
`;

const StyledColumnalSelect = styled(ColumnalSelect)`
  margin-right: 2rem;
`;

const StyledSelectCountryCode = styled(SelectCountryCode)`
  padding-right: 2rem;
`;

const personLabels = [
  {
    first_name: 'First Name',
  },
  {
    last_name: 'Last Name',
  },
];


const addressLabels = [
  { _country: 'Country' },
  { _city: 'City' },
  { _street: 'Street' }
];


export const initialState = {
  person: '',
  contact_type: '',
  email: '',
  website: '',
  tel_country_code: '',
  tel_area_code: '',
  tel_number: '',
  address: '',
};

const contactFormSchema = {
  person: {
    validation: 'required',
  },
  contact_type: {
    validation: 'required',
  },
  email: {
    validation: [{ required_if: ['contact_type', 'email'] }],
  },
  website: {
    validation: [{ required_if: ['contact_type', 'website'] }],
  },

  tel_country_code: {
    validation: [
      {
        required_if: ['contact_type', 'mobile'],
      },
      {
        required_if: ['contact_type', 'landline'],
      },
    ],
  },
  tel_area_code: {
    validation: [
      { required_if: ['contact_type', 'mobile'] },
      {
        required_if: ['contact_type', 'landline'],
      },
    ],
  },

  tel_number: {
    validation: [
      { required_if: ['contact_type', 'mobile'] },
      {
        required_if: ['contact_type', 'landline'],
      },
    ],
  },

  address: {
    validation: [{ required_if: ['contact_type', 'landline'] }],
  },
};

const ContactForm = (props) => {
  // const [
  //   country,
  // ] = [
  //   props.contactInfoForm.data,
  //   props.country,
  //   props.addressInfo,
  // ];

  const [formData, setFormData] = useState(initialState);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const { basePath, trObj, tr, country} = props;
  const history = useHistory();

  console.log('country', country);

  const onChange = (e) => {
    const { name, value } = e.target;
    // const { setDataToForm } = props;
    // setDataToForm({ ...contactInfoFormData, [name]: value });
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    // const { setDataToForm } = props;
    const {
      addContactInfo,
      updateContactInfo,
      setMsgInfo,
      tr,
      // contactInfoForm: { data: contactInfoFormData },
    } = props;

    if (formSubmitting) return;
    let payload = excludeUnsetField(formData);
    try {
      setFormSubmitting(true);
      if (!formData.id) {
        await addContactInfo(payload);
        setMsgInfo({
          success: true,
          msg: [`${tr('Contact added successfully')}`],
        });
      } else {
        await updateContactInfo(formData.id, payload);
        setMsgInfo({
          success: true,
          msg: [`${tr('Contact updated successfully')}`],
        });
      }
      history.push(basePath);
      window.scrollTo(0, 0);
    } catch (e) {
    } finally {
      setFormSubmitting(false);
    }
  };


  const getAddressInfo = () => {
    return props.addressInfo.data.map(({ country_detail, city_detail, street_name, street_no, street_no_suffix, ...rest }) => ({
      ...rest,
      _country: trObj(country_detail),
      _city: trObj(city_detail),
      _street_name: street_name,
      _street_no: street_no,
      _street_no_suffix: street_no_suffix,
    }));
  };

  const { id } = useParams();
  const contactList = useSelector((state) => state.contactInfo);

    // const countryOptions = country
    // .filter(({ tel_code }) => !!tel_code)
    // .map(({ tel_code, ...rest }) => ({
    //   ...rest,
    //   tel_code,
    // }));


    const countryOptions = Array.from(
      new Set(country.map((c) => c.tel_code)) // Use Set to filter unique tel_codes
    )
      .map((tel_code) => {
        const countryData = country.find((c) => c.tel_code === tel_code); // Find the first matching country
        return {
          ...countryData,
          tel_code,
        };
      });


  useEffect(() => {
    if (id) {
      const contacts = contactList.filter((relation) => relation.id == id);
      const contactDetailTemp = contacts.length > 0 ? contacts[0] : {};
      setFormData(contactDetailTemp);
    } else {
      setFormData({});
    }
  }, []);

  return (
    <Wrapper className="form-wrapper">
    <div className="flex">
      <StyledButtonLink
        to={basePath}
        className="option-btn"
        icon="angle double left"
        name="All Entries"
      />
    </div>
    <div className="row">
      <div
        className="col-7 ml-10 mt-30 container"
        style={{
          position: 'relative',
          background: 'var(--admincat-color-grey-1)',
        }}
      >
        <br />

        <Form
          formData={formData}
          onSubmit={onSubmit}
          isLoading={formSubmitting}
          loadingData={formLoading}
          validationSchema={contactFormSchema}
          title="Contact"
        >
          {/* <ColumnalSelect
            onChange={onChange}
            value={formData?.person}
            name="person"
            label="Person"
            showRequiredAsterisk
            labels={personLabels}
            options={props.personalInfo.data}
            // onBlur={handleBlur}
          /> */}

          <StyledSelect
            optionDefaultValue='Choose Person'
            name="person"
            label="Person"
            value={formData?.person}
            options={props.personalInfoOptions}
            onChange={onChange}
            showRequiredAsterisk
            deselectable
            sort={false}
          />
          <StyledSelect
            optionDefaultValue="Choose Contact Type"
            label="Contact Type"
            value={formData?.contact_type}
            name="contact_type"
            id="contact_type"
            localeOptions={props.contactTypeLocaleOptions}
            onChange={onChange}
            showRequiredAsterisk
            sort={true}
          />

          {formData?.contact_type == 'email' && (
            <StyledInput
              placeholder="Email"
              label="Email"
              value={formData?.email}
              name="email"
              type="email"
              onChange={onChange}
              showRequiredAsterisk
            />
          )}

          {formData?.contact_type == 'website' && (
            <StyledInput
              placeholder="Website"
              label="Website"
              value={formData?.website || 'http://'}
              name="website"
              onChange={onChange}
              showRequiredAsterisk
            />
          )}

          {formData?.contact_type == 'mobile' && (
            <React.Fragment>
              <StyledSelectCountryCode
                optionDefaultValue="Choose Country Telephone Code"
                label="Country Telephone Code"
                value={formData?.tel_country_code}
                name="tel_country_code"
                id="tel_country"
                options={countryOptions}
                onChange={onChange}
                showRequiredAsterisk
              />
              <StyledInput
                placeholder="Area Telephone Code"
                label="Area Telephone Code"
                value={formData?.tel_area_code}
                name="tel_area_code"
                id="tel_area_code"
                onChange={onChange}
                showRequiredAsterisk
              />

              <StyledInput
                placeholder="Telephone Number"
                label="Telephone Number"
                value={formData?.tel_number}
                name="tel_number"
                id="tel_number"
                onChange={onChange}
                showRequiredAsterisk
              />
            </React.Fragment>
          )}

          {formData?.contact_type == 'landline' && (
            <React.Fragment>
              <StyledSelectCountryCode
                optionDefaultValue="Choose Country Telephone Code"
                label="Country Telephone Code"
                value={formData?.tel_country_code}
                name="tel_country_code"
                id="tel_country_code"
                options={countryOptions}
                onChange={onChange}
                showRequiredAsterisk
              />
              <StyledInput
                placeholder="Area Telephone Code"
                label="Area Telephone Code"
                value={formData?.tel_area_code}
                name="tel_area_code"
                id="tel_area_code"
                onChange={onChange}
                showRequiredAsterisk
              />

              <StyledInput
                placeholder="Telephone Number"
                label="Telephone Number"
                value={formData?.tel_number}
                name="tel_number"
                id="tel_number"
                onChange={onChange}
                showRequiredAsterisk
              />

              <StyledColumnalSelect
                optionDefaultValue={tr('Choose Address')}
                onChange={onChange}
                value={formData?.address}
                name="address"
                label="Address"
                required
                labels={addressLabels}
                options={getAddressInfo()}
              />
            </React.Fragment>
          )}
          <Switchery
            name="active"
            value={formData?.active}
            onChange={onChange}
            label="Inactive / Active"
            showRightLabel={false}
          />
        </Form>
      </div>
    </div>
    </Wrapper>
  );
};

export default compose(
  withContactType,
  withContactInfo,
  withPersonalInfo,
  withCountry,
  withAddressInfo,
  withTranslation,
  withCustomRouter,
  withMsgInfo,
)(ContactForm);
