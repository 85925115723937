import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from "hoist-non-react-statics";
import * as autoMobileInfoActions from "actions/dashboard/autoMobileInfo";
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC";


const initialState = {
  loaded: false,
}

export const withAutoMobileInfo = (WrappedComponent) => {

  class WithAutoMobileInfo extends BaseHOC {

    state = { ...initialState }

    async componentDidMount() {
      const { getAutoMobile } = this.props
      if (!this.state.loaded)  {
        await getAutoMobile();
        this.setState({...initialState, loaded: true });
      }
    }

    get autoMobileInfoOptions() {
      const { autoMobileInfo } = this.props;
      return autoMobileInfo.data.map(({ id, ...rest }) => {
        return {
          id,
          name: `${rest.means_of_transport}`,
        }
      });
    }

    deleteAutoMobileInfoById = async (data) => {
      const { deleteAutoMobileById } = this.props
      await this._deleteById(deleteAutoMobileById, data);
    }

    deleteBulkAutoMobileInfo = async (data) => {
      const { deleteBulkAutoMobile } = this.props
      await this._deleteBulk(deleteBulkAutoMobile, data);
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          loaded={this.state.loaded}
          autoMobileInfoOptions={this.autoMobileInfoOptions}
          deleteBulkAutoMobileInfo={this.deleteBulkAutoMobileInfo}
          deleteAutoMobileInfoById={this.deleteAutoMobileInfoById}
        
        />
      )
    }
  }
  const mapStateToProps = ({ autoMobileInfo }) => ({ autoMobileInfo })
  const AutoMobileInfo = connect(mapStateToProps, autoMobileInfoActions)(WithAutoMobileInfo);
  hoistNonReactStatics(AutoMobileInfo, WrappedComponent)
  return compose(withBaseHOCProps)(AutoMobileInfo);
}
