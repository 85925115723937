import React from 'react';
import { emptyState } from 'assets/icons/emptyState';
import { fileIcon } from 'assets/icons/fileIcon';
import { cpuIcon } from 'assets/icons/docCategory';
import { multiDocIcon } from 'assets/icons/multi-doc-icon';
import { docType } from 'assets/icons/docType';
import { calendarEvent } from 'assets/icons/calendarEvent';
import { calendarMonth } from 'assets/icons/calendarMonth';
import { calendarYear } from 'assets/icons/calenderYear';
import { calendarRange } from 'assets/icons/calendarRange';
import { cancle } from 'assets/icons/cancle';
import { addIcon } from 'assets/icons/add';
import { trashIcon } from 'assets/icons/trash';
import { layoutIcon } from 'assets/icons/layout';
import { person } from 'assets/icons/person';
import { organization } from 'assets/icons/organization';
import { cancel_2 } from 'assets/icons/cancel-2';
import { cancel_dash } from 'assets/icons/cancel_dash';
import { refresh } from 'assets/icons/refresh';
import { assets } from 'assets/icons/assets';
import { documents } from 'assets/icons/documents';
import { donutChartX } from 'assets/icons/donutChartX';
import { email } from 'assets/icons/email';
import { graph } from 'assets/icons/graph';
import { incomeVsExpense } from 'assets/icons/incomVsExpense';
import { liabilities } from 'assets/icons/liabilities';
import { liabilitiesVSassets } from 'assets/icons/liabilitiesVSassets';
import { space_group } from 'assets/icons/space_group';
import { storage } from 'assets/icons/storage';
import { top5 } from 'assets/icons/top5';
import { progressBar } from 'assets/icons/progressBar';
import { settingsIcon } from 'assets/icons/settings';
import { notificationSlash } from 'assets/icons/notificationSlash';
import { preview } from 'assets/icons/preview';
import { people } from 'assets/icons/people';
import { barChart } from 'assets/icons/barChart';
import { pieChart } from 'assets/icons/pieChart';
import { horizontalChart } from 'assets/icons/horizontalChart';
import { arrowLineChart } from 'assets/icons/arrowLineChart';

export const icons = {
  emptyState: emptyState,
  fileIcon: fileIcon,
  cpuIcon: cpuIcon,
  multiDocIcon: multiDocIcon,
  docType: docType,
  calendarEvent: calendarEvent,
  calendarMonth: calendarMonth,
  calendarYear: calendarYear,
  calendarRange: calendarRange,
  cancle: cancle,
  addIcon: addIcon,
  trashIcon: trashIcon,
  layoutIcon: layoutIcon,
  person: person,
  organization: organization,
  cancel_2: cancel_2,
  cancel_dash: cancel_dash,
  refresh: refresh,
  assets: assets,
  documents: documents,
  donutChartX: donutChartX,
  email: email,
  graph: graph,
  incomeVsExpense: incomeVsExpense,
  liabilities: liabilities,
  liabilitiesVSassets: liabilitiesVSassets,
  space_group: space_group,
  storage: storage,
  top5: top5,
  progressBar: progressBar,
  settingsIcon: settingsIcon,
  notificationSlash: notificationSlash,
  preview: preview,
  people: people,
  barChart: barChart,
  pieChart: pieChart,
  horizontalChart: horizontalChart,
  arrowLineChart: arrowLineChart,
};

//exceptions
export const editIcon = (
  <img src="https://img.icons8.com/external-kiranshastry-gradient-kiranshastry/30/000000/external-edit-miscellaneous-kiranshastry-gradient-kiranshastry.png" />
);
export const deleteIcon = (
  <img src="https://img.icons8.com/external-kiranshastry-gradient-kiranshastry/30/000000/external-delete-miscellaneous-kiranshastry-gradient-kiranshastry.png" />
);

export const viewIcon = (
  <img src="https://img.icons8.com/external-kiranshastry-gradient-kiranshastry/30/000000/external-view-delivery-kiranshastry-gradient-kiranshastry.png" />
);
