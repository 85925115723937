import styled from 'styled-components';

export const DropdownMenu = styled.div`
  margin-top: .75rem; 
  padding: 5px 0px;
  min-width: 6rem;
  position: absolute;
  top: 50px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  // background-color: #fff;
  background: var(--admincat-color-grey-0);
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  right: 30px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  text-align: left;

  &:after {
    right: 12px;
    left: auto;
    position: absolute;
    top: -5px;
    display: inline-block;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
    border-left: 5px solid transparent;
    content: '';
  }
  &:before {
    position: absolute;
    top: -6px;
    right: 12px;
    display: inline-block;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(0, 40, 100, 0.12);
    border-left: 5px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
  }
}
`;

// const DropdownMenuItem = styled.span`
//   display: flex;
//   padding: 2px 10px;
//   left: 0px;
//   width: 100%;
//   position: relative;
//   background: transparent;
//   font-weight: 400;
//   color: #6e7687;
//   cursor: pointer;
//   font-weight: 400;
//   letter-spacing: 1px;
//   height: 30px;
//   line-height: 30px;
//   font-size: 14px;
//   color: ${({ header }) => (header ? '#000' : '')};

//   > * {
//     width: 100%;
//     color: inherit;
//   }
// `;

const DropdownMenuItem = styled.span`
  display: flex;
  padding: 2px 10px;
  left: 0px;
  width: 100%;
  position: relative;
  background: transparent;
  font-weight: 400;
  color: #6e7687;
  cursor: pointer;
  letter-spacing: 1px;
  min-height: 30px; 
  line-height: 1.2; 
  font-size: 14px;
  color: ${({ header }) => (header ? '#000' : '')};
  white-space: normal; 
  word-wrap: break-word; 
  padding-top: 5px; 
  padding-bottom: 5px; 

  > * {
    width: 100%;
    color: inherit;
  }
`;

DropdownMenu.Item = DropdownMenuItem;
