import Membership from './Membership';
import MembershipForm from './Membership/Form';
import Travel from './Travel';
import TravelForm from './Travel/Form';

const path = '/dashboard/data/leisure';

export default [
  {
    name: 'Membership',
    path: `${path}/membership`,
    icon: 'user',
    component: Membership,
    exact: true,
  },
  {
    name: 'Membership Form',
    path: [
      `${path}/membership/create`,
      `${path}/membership/:id`,
      `${path}/membership/:id/update`,
    ],
    icon: 'user',
    component: MembershipForm,
    exact: true,
  },

  {
    name: 'Travel',
    path: `${path}/travel`,
    icon: 'map marker',
    component: Travel,
    exact: true,
  },
  {
    name: 'Travel Form',
    path: [
      `${path}/travel/create`,
      `${path}/travel/:id`,
      `${path}/travel/:id/update`,
    ],
    icon: 'map marker',
    component: TravelForm,
    exact: true,
  },
];

export const redirects = [
  {
    from: `${path}`,
    to: `${path}/membership`,
  },
  {
    from: `${path}/*`,
    to: `${path}/membership`,
  },
];
