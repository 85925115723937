import { BaseActionClass } from "../common/BaseActionClass"
import { WORK_ABSENCE } from '../types'

const path = "/dashboard/work_absence";
const name = "Work Absence";

class WorkAbsenceInfo extends BaseActionClass {

  constructor() { super(path, WORK_ABSENCE, name) }

  addWorkAbsence = this._add;

  updateWorkAbsence = this._update;

  deleteWorkAbsenceById = this._deleteById;

  getWorkAbsence = this._getAll;

  getWorkAbsenceById = this._getById;

  deleteBulkWorkAbsence = this._deleteBulk;
}

export const { 
  addWorkAbsence, 
  updateWorkAbsence, 
  deleteWorkAbsenceById,
  getWorkAbsenceById, 
  getWorkAbsence,
  deleteBulkWorkAbsence,
} = new WorkAbsenceInfo()

