import React, { Fragment, useState, useRef, useEffect } from 'react';
import compose from 'lodash/fp/compose';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import {
  Select,
  Input,
  InputDatePicker,
  Form,
  ButtonLink,
} from '../../../../common/FormItems';
import {
  withBankAccountType,
  withBankAccessType,
  withCurrency,
  withCustomRouter,
  withBankAccount,
  withMsgInfo,
  withTranslation,
} from 'components/hoc';
import { ColumnalSelect } from '../../../../common/FormItems';
import validator from './validateForm';
import { excludeUnsetField } from 'components/utils/excludeUnsetField';
import {
  removeOwnerUtil,
  addOwnerUtil,
  accountDetailsRules,
  initialStateData,
  getReferenceAccountData,
} from './utils';
import axios from '../../../../../axios';
import API from 'utils/api';
import { bankAccountActions } from 'actions';


const Wrapper = styled.div`
`;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const AccountDateWrapper = styled.div`
  display: flex;
  margin-left: ${(props) => (props.margin ? '2em' : '0')};
  & > div:nth-child(2) {
    margin-left: 2em;
  }
`;

const OwnerRowRapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div:first-child {
    flex-basis: 60%;
  }
`;

const AddOwnerButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  i {
    color: green;
    font-weight: 600%;
    font-size: 24px;
  }
`;

const RemoveOwnerButton = styled.button`
  visibility: ${(props) => (props.hideButton ? 'hidden' : 'visible')};
  background: transparent;
  border: none;
  cursor: pointer;
  margin-left: 2em;
  i {
    color: red;
    font-size: 18px;
  }
`;

const OrganizationLoadingIcon = styled.i`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  top: 50%;
  left: 50%;
`;

const StyledSelect = styled(Select)`
  padding-right: 2rem;
`;

const StyledInput = styled(Input)`
  padding-right: 1.6rem;
`;

const StyledColumnalSelect = styled(ColumnalSelect)`
  margin-right: 2rem;
`;

const StyledInputDatePicker = styled(InputDatePicker)`
  padding-right: 2rem;
`;

const StyledMultiDatePicker = styled(InputDatePicker)`
  padding-right: 0.5rem;
  padding-left: 0.8rem;
`;

const bankAccountFormSchema = {
  account_type: {
    validation: [{ required_if: ['accountDetails.account_type', ''] }],
  },
  access_type: {
    validation: [{ required_if: ['accountDetails.access_type', ''] }],
  },
  BIC: {
    validation: [{ required_if: ['accountDetails.BIC', ''] }],
  },
  currency: {
    validation: [{ required_if: ['accountDetails.currency', ''] }],
  },
  currency: {
    validation: [{ required_if: ['accountDetails.currency', ''] }],
  },
  valid_from: {
    validation: [{ required_if: ['accountDetails.valid_from', ''] }],
  },
  account_no: {
    validation: [
      { required_if: ['accountDetails.account_no', ''] },
      { min: 4 },
      { max: 18 },
    ],
  },
  // owner: {
  //   validation: [{ required_if: ['owners[0].owner', ''] }],
  // },
};

const BankAccountForm = (props) => {
  const {
    routeParams,
    getOrganizationWithBIC,
    tr,
    trObj,
    bankAccountTypes,
    bankAccessTypes,
    bankBIC,
    bankAccounts,
    currencyLocaleOptions,
    basePath,
    detailMode,
    setMsgInfo,
  } = props;

  const { id: bankAccountId } = routeParams;
  const [formData, setFormData] = useState(initialStateData);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (bankAccountId) {
      try {
        setFormLoading(true);
        axios
          .get(`${API}/dashboard/bank_account/${bankAccountId}`)
          .then((response) => {
            const data = response.data;
            setFormData({
              ...initialStateData,
              accountDetails: {
                account_type: data.account_type,
                access_type: data.access_type,
                BIC: data.BIC_data?.BIC || '',
                account_no: data.account_no,
                valid_from: data.valid_from,
                valid_to: data.valid_to,
                currency: data.currency,
                IBAN: data.IBAN || '',
                account_ref: data.account_ref || '',
              },
              owners: data.owners.map((owner, index) => ({
                [`owner-${index}`]: owner.owner_data.owner,
                [`valid_from-${index}`]: owner.valid_from,
                [`valid_to-${index}`]: owner.valid_to,
              })),
              errors: {
                accountDetails: {},
                owners: data.owners.map(() => ({})),
              },
            });
  
            // Dispatch the action to fetch organization data with BIC
            if (data.BIC_data?.BIC) {
              dispatch(bankAccountActions.getOrganizationWithBIC(data.BIC_data.BIC));
            }
  
            setFormLoading(false);
          })
          .catch((e) => {
            setFormData({ ...initialStateData });
            setFormLoading(false);
          });
      } catch (e) {
        setFormData({ ...initialStateData });
        setFormLoading(false);
      }
    } else {
      setFormData({ ...initialStateData });
      setFormLoading(false);
    }
  }, [bankAccountId, dispatch]);


  const onSubmit = async () => {
    if (formSubmitting) return;
    // Make a copy of the form data for submission
    const payloadData = JSON.parse(JSON.stringify(formData));
    // Extract the accountDetails date values directly
    const valid_from = payloadData.accountDetails.valid_from;
    const valid_to = payloadData.accountDetails.valid_to;
    
    // Ensure these values are part of the main payload
    const payload = {
      ...excludeUnsetField(payloadData),
      valid_from: valid_from,
      valid_to: valid_to
    };
    
    const { createBankAccount, updateBankAccount, basePath, history } = props;
    try {
      setFormSubmitting(true);
      
      if (bankAccountId) {
        await updateBankAccount(bankAccountId, payload);
        setMsgInfo({
          success: true,
          msg: [`${tr('Bank Account updated successfully')}`],
        });
      } else {
        await createBankAccount(payload);
        setMsgInfo({
          success: true,
          msg: [`${tr('Bank Account created successfully')}`],
        });
      }
      
      history.push(basePath);
    } catch (error) {
      console.error("Error in form submission:", error);
      setMsgInfo({
        success: false,
        msg: [`${tr('An error occurred. Please try again.')}`],
      });
    } finally {
      setFormSubmitting(false);
    }
  };

  const getOrganizationFromBic = (BIC) => {
    getOrganizationWithBIC(BIC);
  };

  // const getOrganizationFromBic = (BIC) => {
  //   dispatch(getOrganizationWithBIC(BIC));
  // };

  const handleInputChange = ({ target: { name, value } }) => {
    let { isValid, errors } = validator.validate(
      { [name]: value },
      accountDetailsRules(),
    );
    
    setFormData((state) => {
      const accountDetails = state.accountDetails || {}; 
      return {
        ...state,
        accountDetails: {
          ...accountDetails,
          [name]: value,
        },
        errors: {
          ...state.errors,
          accountDetails: {
            ...state.errors.accountDetails,
            [name]: errors?.[name],
          },
        },
      };
    });
    
    if (name === 'BIC' && value.length > 3) {
      getOrganizationFromBic(value);
    }
  };
  
  const handleOwnerInputChange = ({ target: { name, value } }) => {
    const fieldIndex = Number.parseInt(name.split('-')[1]);
    const updatedOwners = formData.owners.map((owner, index) => {
      if (index === fieldIndex) {
        return {
          ...owner,
          [name]: value,
        };
      }
      return owner;
    });
  
    setFormData((state) => ({
      ...state,
      owners: updatedOwners,
    }));
  };

  const removeOwner = (removeIndex) => {
    let { newOwners, errorFields } = removeOwnerUtil(formData.owners, removeIndex);
    setFormData((state) => ({
      ...state,
      owners: newOwners,
    }));
  };

  const addOwner = (data = {}) => {
    const newOwners = addOwnerUtil(formData.owners);
    setFormData((state) => ({
      ...state,
      ...data,
      owners: newOwners,
      errors: { ...state.errors, owners: [...state.errors.owners, {}] },
    }));
  };

  const renderMultipleOwners = () => {
    const { tr } = props;
  
    const ownerFields = formData.owners.map((owner, i) => (
      <OwnerRowRapper key={i}>
        <StyledColumnalSelect
          label="Owner"
          optionDefaultValue="Choose Owner"
          value={owner[`owner-${i}`] || ''}
          error={formData.errors.owners[i]?.[`owner-${i}`]}
          options={props.personalInfo.data}
          columns={['first_name', 'last_name']}
          name={`owner-${i}`}
          labels={[{ first_name: tr('First Name') }, { last_name: tr('Last Name') }]}
          onChange={handleOwnerInputChange}
        />
        <AccountDateWrapper margin>
          <InputDatePicker
            label={tr('Valid From')}
            showRequiredAsterisk
            value={owner[`valid_from-${i}`] || '1990-10-03'}
            name={`valid_from-${i}`}
            onChange={handleOwnerInputChange}
          />
          <StyledMultiDatePicker
            label={tr('Valid To')}
            showRequiredAsterisk
            value={owner[`valid_to-${i}`] || '9999-12-31'}
            name={`valid_to-${i}`}
            onChange={handleOwnerInputChange}
          />
        </AccountDateWrapper>
        {!detailMode && (
        <RemoveOwnerButton
          onClick={() => removeOwner(i)}
          hideButton={i === 0}
          disabled={i === 0}
        >
          <i className="fa fa-minus-circle"></i>
        </RemoveOwnerButton>
      )}
      </OwnerRowRapper>
      
    ));
    return (
      <Fragment>
        {ownerFields}
        {!detailMode && (
        <AddOwnerButton type="button" onClick={() => addOwner()}>
          <i className="fa fa-plus"></i>
        </AddOwnerButton>
      )}
      </Fragment>
    );
  };

  const renderAccountOwnersField = () => {
    const { tr } = props;
    const accountDetails = formData?.accountDetails || {};
    const owners = formData?.owners || [];
    const errors = formData?.errors?.owners || [];
    
    return (
      <Fragment>
        {accountDetails.access_type === 'IND' ? (
          <StyledColumnalSelect
            label="Owner"
            optionDefaultValue="Choose Owner"
            value={owners[0]?.['owner-0'] || ''}
            error={errors[0]?.['owner'] || ''}
            name="owner-0"
            options={props.personalInfo.data}
            columns={['first_name', 'last_name']}
            labels={[
              { first_name: tr('First Name') },
              { last_name: tr('Last Name') },
            ]}
            onChange={handleOwnerInputChange}
          />
        ) : (
          renderMultipleOwners()
        )}
      </Fragment>
    );
  };

  const maskAccountNumber = (accountNumber) => {
    if (!accountNumber) return '';
    // Always show 8 stars regardless of original length
    const stars = '********';
    // Always show last 3 digits if available
    const lastThreeDigits = accountNumber.length >= 3 
      ? accountNumber.slice(-3) 
      : accountNumber;
    
    return `${stars}${lastThreeDigits}`;
  };

  const displayAccountNumber = detailMode 
    ? maskAccountNumber(formData.accountDetails.account_no)
    : formData.accountDetails.account_no;
    
return (
<Wrapper className="form-wrapper">
    <div className="flex">
      <StyledButtonLink
        to={basePath}
        className="option-btn"
        icon="angle double left"
        name="All Entries"
      />
    </div>
    <div className="row">
      <div
        className="col-7 ml-10 mt-30 container"
        style={{
          position: 'relative',
          background: 'var(--admincat-color-grey-1)',
        }}
      >
        <br/>

          <Form
            title="Bank Account"
            onSubmit={onSubmit}
            formData={formData}
            validationSchema={bankAccountFormSchema}
            isLoading={formSubmitting}
            loadingData={formLoading}
          >
              <Fragment>
                <StyledSelect
                  options={bankAccountTypes.map((type) => ({
                    ...type,
                    name: trObj(type),
                  }))}
                  errors={formData.errors.accountDetails}
                  label="Account Type"
                  name="account_type"
                  value={formData.accountDetails.account_type}
                  optionDefaultValue="select account type"
                  onChange={handleInputChange}
                  showRequiredAsterisk
                  sort={false}
                />
                <StyledSelect
                  options={bankAccessTypes.map((type) => ({
                    ...type,
                    name: trObj(type),
                  }))}
                  errors={formData.errors.accountDetails}
                  label="Access Type"
                  name="access_type"
                  value={formData.accountDetails.access_type}
                  optionDefaultValue="Select access type"
                  onChange={handleInputChange}
                  showRequiredAsterisk
                  sort={false}
                />
                <StyledInput
                  type="text"
                  label="bic (swift code)"
                  name="BIC"
                  value={formData.accountDetails.BIC}
                  errors={formData.errors.accountDetails}
                  maxLength="11"
                  minLength="11"
                  showRequiredAsterisk
                  onChange={handleInputChange}
                  placeholder="bic (swift code)"
                />
                <div className="position-relative">
                  <StyledInput
                    type="text"
                    label="Bank"
                    value={
                      bankBIC.data
                        ? bankBIC.data.org_name
                        : `${
                            bankBIC.status === 'done' &&
                            formData.accountDetails.BIC !== ''
                              ? `Organisation with ${formData.accountDetails.BIC} NotFound`
                              : ''
                          }`
                    }
                    disabled
                    placeholder="Organisation"
                  />
                  <OrganizationLoadingIcon
                    show={bankBIC.status === 'pending'}
                    className="fa fa-spinner fa-spin"
                  ></OrganizationLoadingIcon>
                </div>
                <StyledInput
                  type="text"
                  label="Account Number"
                  placeholder="Account Number"
                  name="account_no"
                  pattern="\d*"
                  maxLength="18"
                  minLength="4"
                  // errors={formData.errors.accountDetails['account_no']}
                  errors={formData.errors.accountDetails.account_no}
                  // value={formData.accountDetails.account_no}
                  value={displayAccountNumber}
                  showRequiredAsterisk
                  onChange={handleInputChange}
                />
                <StyledInput
                  type="text"
                  label="IBAN"
                  name="IBAN"
                  maxLength="40"
                  placeholder="IBAN"
                  value={formData.accountDetails.IBAN}
                  errors={formData.errors.accountDetails}
                  onChange={handleInputChange}
                />
                <StyledSelect
                  localeOptions={currencyLocaleOptions}
                  errors={formData.errors.accountDetails}
                  label="Currency"
                  name="currency"
                  value={formData.accountDetails.currency}
                  optionDefaultValue="select currency"
                  onChange={handleInputChange}
                  showRequiredAsterisk
                />
                {formData.accountDetails.account_type !== 'checkacc' && (
                  <StyledColumnalSelect
                    label={tr('reference checking account')}
                    value={Number.parseInt(formData.accountDetails.account_ref)}
                    name="account_ref"
                    optionDefaultValue="Choose Reference Checking Account"
                    options={getReferenceAccountData(bankAccounts)}
                    columns={['org_name', 'BIC', 'account_no']}
                    labels={[
                      { org_name: tr('Organisation') },
                      { BIC: tr('BIC') },
                      { account_no: tr('Account Number') },
                    ]}
                    onChange={handleInputChange}
                  />
                )}
                <div className="row">
                <div className="col-md-6">
                  <InputDatePicker
                    label={tr('Valid From')}
                    showRequiredAsterisk
                    value={formData.accountDetails.valid_from || ''}
                    name="valid_from"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <StyledInputDatePicker
                    label={tr('Valid To')}
                    value={formData.accountDetails.valid_to || '9999-12-31'}
                    name="valid_to"
                    onChange={handleInputChange}
                  />
                </div>
                </div>
                {renderAccountOwnersField()}
              </Fragment>

          </Form>
        </div>
      </div>
    </Wrapper>
  );
};

export default compose(
  withBankAccount,
  withBankAccountType,
  withBankAccessType,
  withCurrency,
  withCustomRouter,
  withRouter,
  withMsgInfo,
  withTranslation,
)(BankAccountForm);