import React from 'react';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import * as travelInfoActions from 'actions/dashboard/travelInfo';
import { BaseHOC, withBaseHOCProps } from '../common/BaseHOC';

const initialState = {
  loaded: false,
};

export const withTravelInfo = (WrappedComponent) => {
  class WithTravelInfo extends BaseHOC {
    state = { ...initialState };

    async componentDidMount() {
      const { getTravel } = this.props;
      if (!this.state.loaded) {
        await getTravel();
        this.setState({ ...initialState, loaded: true });
      }
    }

    deleteTravelInfoById = async (data) => {
      const { deletTravelById } = this.props;
      await this._deleteById(deletTravelById, data);
    };

    // deleteBulkTravelInfo = async (data) => {
    //   const { deleteConfirmation, deleteBulkTravel } = this.props;
    //   try {
    //     await deleteConfirmation.initBulk(deleteBulkTravel, data);
    //   } catch (e) {}
    // };

    deleteBulkTravelInfo = async (data) => {
      const { deleteBulkTravel } = this.props;
      await this._deleteBulk(deleteBulkTravel, data);
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          loaded={this.state.loaded}
          deleteBulkTravelInfo={this.deleteBulkTravelInfo}
          deleteTravelInfoById={this.deleteTravelInfoById}
        />
      );
    }
  }
  const mapStateToProps = ({ travelInfo }) => ({ travelInfo });
  const TravelInfo = connect(mapStateToProps, travelInfoActions)(WithTravelInfo);
  hoistNonReactStatics(TravelInfo, WrappedComponent);
  return compose(withBaseHOCProps)(TravelInfo);
};
