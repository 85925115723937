import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from "hoist-non-react-statics";
import * as workAbsenceInfoActions from "actions/dashboard/workAbsenceInfo";
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC";

const initialState = {
  loaded: false,
}

export const withWorkAbsenceInfo = (WrappedComponent) => {

  class WithWorkAbsenceInfo extends BaseHOC {

    state = { ...initialState }

    async componentDidMount() {
      const { getWorkAbsence } = this.props
      if (!this.state.loaded)  {
        await getWorkAbsence();
        this.setState({...initialState, loaded: true });
      }
    }
    
    deleteWorkAbsenceInfoById = async (data) => {
      const { deleteWorkAbsenceById } = this.props
      await this._deleteById(deleteWorkAbsenceById, data);
    }

    deleteBulkWorkAbsenceInfo = async (data) => {
      const { deleteBulkWorkAbsence } = this.props
      await this._deleteBulk(deleteBulkWorkAbsence, data);
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          loaded={this.state.loaded}
          deleteBulkWorkAbsenceInfo={this.deleteBulkWorkAbsenceInfo}
          deleteWorkAbsenceInfoById={this.deleteWorkAbsenceInfoById}
        
        />
      )
    }
  }
  const mapStateToProps = ({ workAbsenceInfo }) => ({ workAbsenceInfo })
  const WorkAbsenceInfo = connect(mapStateToProps, workAbsenceInfoActions)(WithWorkAbsenceInfo);
  hoistNonReactStatics(WorkAbsenceInfo, WrappedComponent)
  return compose(withBaseHOCProps)(WorkAbsenceInfo);
}
