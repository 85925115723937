import * as types from '../../../actions/dashboard/finance/types';

const initialSate = {
  bankAccountTypes: [],
  bankAccessTypes: [],
  bankAccounts: [],
  bankBIC: {
    status: 'idle',
    data: null,
  },
  singleBankAccount: {
    owners: [],
  },
  loading: false,
};


function bankAccount(state = initialSate, actions = {}) {
  switch (actions.type) {
    case types.GET_BANK_ACCOUNT_TYPES:
      return { ...state, bankAccountTypes: actions.payload };

    case types.GET_BANK_ACCESS_TYPES:
      return { ...state, bankAccessTypes: actions.payload };

    case types.GET_ORGANIZATION_WITH_BIC_STARTED:
      return {
        ...state,
        bankBIC: {
          ...state.bankBIC,
          status: 'pending',
        },
      };

    case types.GET_ORGANIZATION_WITH_BIC:
      return {
        ...state,
        bankBIC: {
          ...state.bankBIC,
          data: actions.payload,
          status: 'done',
        },
      };

    // case 'SET_BIC_DATA':
    //     return {
    //       ...state,
    //       bankBIC: {
    //         data: actions.payload,
    //         status: 'done',
    //       },
    //     };

    case types.CREATE_BANK_ACCOUNT:
      return {
        ...state,
        bankBIC: {
          status: 'idle',
          data: null,
        },
      };
    case types.GET_ALL_BANK_ACCOUNT:
      return {
        ...state,
        bankAccounts: actions.payload,
      };
    case types.DELETE_BULK_BANK_ACCOUNT:
      return {
        ...state,
        bankAccounts: [
          ...state.bankAccounts.filter((item) => !actions.payload.includes(item.id)),
        ],
      };

    case types.DELETE_BANK_ACCOUNT:
      return {
        ...state,
        bankAccounts: [
          ...state.bankAccounts.filter((item) => item.id !== actions.payload),
        ],
      };
    case types.GET_BANK_ACCOUNT_BY_ID:
      return {
        ...state,
        singleBankAccount: actions.payload,
        bankBIC: {
          ...state.bankBIC,
          data: {
            ...actions.payload.BIC_data,
          },
        },
      };
    case types.CLEAR_SINGLE_BANK_ACCOUNT_DATA:
      return {
        ...state,
        singleBankAccount: {
          owners: [],
        },
        bankBIC: {
          status: 'idle',
          data: null,
        },
      };
    case types.CREATE_BANK_ACCOUNT:
      return {
        ...state,
        bankAccounts: [...state.bankAccounts, actions.payload],
      };

    case types.UPDATE_BANK_ACCOUNT:
      return {
        ...state,
        bankAccounts: [...state.bankAccounts].map((item) => {
          if (item.id === actions.payload.id) {
            return actions.payload;
          }
          return item;
        }),
        bankBIC: {
          status: 'idle',
          data: null,
        },
      };
    case types.BANK_ACCOUNT_LOADER:
      return {
        ...state,
        loading: !state.loading,
      };

    case types.CREATE_BANK_ACCOUNT:
      return {
        ...state,
        bankBIC: {
          status: 'idle',
          data: null,
        },
      };

    case types.BANK_ACCOUNT_FORM:
    case types.BANK_ACCOUNT_FORM_VIEW:
      return {
        ...state,
        singleBankAccount: {
          owners: [],
        },
        bankBIC: {
          status: 'idle',
          data: null,
        },
      };
    default:
      return state;
  }
}

export default {
  bankAccount,
  // bankAccountView,
  // bankAccountForm,
};
