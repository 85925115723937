/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
import { Icon, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { BaseColumnalSelect } from './BaseColumnalSelect';
import InputDatePicker from './DatePicker';
import RadioButtonArray from './RadioButtonArray';
import SquareCheckbox from './SquareCheckbox';
import FileInput from './FileInput';
import TextArea from './TextArea';
import FormModal from './FormModal';
import ViewModal from './ViewModal';
import Form from './Form';
import FormNotAuth from './FormNotAuth';
import OrgForm from './OrgForm';
import Button from './Button';
import { withTranslation, withFieldError } from 'components/hoc';
import { CheckBoxWrapper } from './styled';
import Input, { inputStyle } from './Input';
import { SelectOptions, SelectOptionsCountry } from './SelectOptions';
import RichTextEditor from './RichTextEditor';
import TextEditor from './TextEditor';
import Editor from './Editor';
import FormMode from './FormMode';
import './index.css';
import { StyledButton } from './Form';
import TransactionForm from './TransactionForm';
// import { Select as SelectEntry} from 'components/common/FormItems';

const func = () => {};

const StyledSelect = styled.select`
  &&& {
    ${inputStyle}
  }

  border: ${({ error }) => (error ? '1px solid red !important' : 'inherit')};
`;

// const StyledButtonLink = styled(SelectEntry)`
//   span {
//     text-transform: uppercase;
//   }
// `;

const StyledCancelButtonLink = styled(StyledButton)`
  background: #e4f1ff;
  border: solid 1px #e4f1ff;
  color: #4c81be;
`;

const Select = compose(
  withFieldError,
  withTranslation,
)(({ tr, className, isLoading, error, deselectable, ...props }) => {
  const isDefaultValueSelected = props.value === "";
  return (
    <div className={`form-group w-100 ${className}`} style={{ ...props.style }}>
      {props.label && (
        <label className="form-label" htmlFor={props.name}>
          {tr(props.label || '')}
          {(props.required || props.showRequiredAsterisk) && (
            <span className="form-required">*</span>
          )}
          {isLoading && <Icon loading name="asterisk" />}
        </label>
      )}
      <StyledSelect
        className="select form-select"
        error={error}
        value={props.value || ''}
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
        style={{
          background: 'var(--admincat-color-grey-6)',
          color: isDefaultValueSelected ? props.optionDefaultValueColor : 'var(--admincat-color-grey-2)',
        }}
      >
        <option 
          disabled={!deselectable} 
          value="" 
          style={{ color: props.optionDefaultValueColor || 'inherit' }}
        >
          {tr(props.optionDefaultValue || '')}
        </option>
        <SelectOptions {...props} />
      </StyledSelect>
      <small id="passwordHelp" className="text-danger">
        {tr(error || '')}
      </small>
    </div>
  );
});

Select.defaultProps = {
  style: {},
  value: '',
  onChange: () => {},
  deselectable: true,
  optionDefaultValueColor: 'var(--admincat-color-grey-3)',
};



const SelectCountryCode = compose(
  withFieldError,
  withTranslation,
)(({ tr, className, isLoading, error, deselectable, ...props }) => {
  const isDefaultValueSelected = props.value === "";
  return (
    <div className={`form-group w-100 ${className}`} style={{ ...props.style }}>
      {props.label && (
        <label className="form-label" htmlFor={props.name}>
          {tr(props.label || '')}
          {(props.required || props.showRequiredAsterisk) && (
            <span className="form-required">*</span>
          )}
          {isLoading && <Icon loading name="asterisk" />}
        </label>
      )}
      <StyledSelect
        className="select form-select"
        error={error}
        value={props.value || ''}
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
        style={{
          background: 'var(--admincat-color-grey-6)',
          color: isDefaultValueSelected ? props.optionDefaultValueColor : 'var(--admincat-color-grey-2)',
        }}
      >
        <option disabled={!deselectable} value="">
          {tr(props.optionDefaultValue || '')}
        </option>
        <SelectOptionsCountry {...props} />
      </StyledSelect>
      <small id="passwordHelp" className="text-danger">
        {tr(error || '')}
      </small>
    </div>
  );
});
SelectCountryCode.defaultProps = {
  optionDefaultValueColor: 'var(--admincat-color-grey-3)',
};



const NewEntriesSelect = compose(
  withFieldError,
  withTranslation,
)(
  ({
    tr,
    className,
    isLoading,
    error,
    deselectable,
    add,
    toggleEntryField,
    showNewEntryOption = true,
    ...props
  }) => {
    const propose_new = tr('Propose a new entry');
    const new_entry = tr('New Entry');
    const isDefaultValueSelected = props.value === "";

    return (
      <div className={`form-group w-100`} style={{ ...props.style }} >
        {props.label && (
          <label className="form-label" htmlFor={props.name}>
            {tr(props.label || '')}
            {(props.required || props.showRequiredAsterisk) && (
              <span className="form-required">*</span>
            )}
            {isLoading && <Icon loading name="asterisk" />}
          </label>
        )}
        <StyledSelect
          // className="select form-select"
          error={error}
          value={props.value || ''}
          name={props.name}
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled}
          style={{
            background: 'var(--admincat-color-grey-6)',
            color: isDefaultValueSelected ? props.optionDefaultValueColor : 'var(--admincat-color-grey-2)',
          }}
        >
          <option disabled={!deselectable} value="">
            {tr(props.optionDefaultValue || '')}
          </option>
          <SelectOptions {...props} />
          {showNewEntryOption && (
            <option
              value={'proposeValue'}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              {tr('Propose a new entry')}
            </option>
          )}
        </StyledSelect>
        <small id="passwordHelp" className="text-danger">
          {tr(error || '')}
        </small>
        <FormModal
          title={`${propose_new} (${props?.entryType})`}
          open={add}
          toggleForm={props?.closeEntryForm}
          style={{
            background: 'var(--admincat-color-grey-6)',
          }}
          onSubmit={() => null}
          renderActionButtons={() => (
            <div className="card-footer text-right">
              <div className="d-flex">
                <StyledCancelButtonLink
                  type="button"
                  name={tr('Cancel')}
                  onClick={props?.closeEntryForm}
                  cancelButton
                />
                <StyledButton
                  type="button"
                  onClick={props?.onCreateNewEntry}
                  actionButton
                  isLoading={isLoading}
                  name={tr('Save')}
                />
              </div>
            </div>
          )}
        >
          <div className="col">
            <Input
              onChange={props.onEntryChange}
              value={props.entryValue}
              name={'name'}
              label={`${new_entry} (${props?.entryType})`}
              required
            />
          </div>
          <div className="col">
            <label className="form-label" htmlFor={props.name}>
              {tr('Language' || '')}
              {(props.required || props.showRequiredAsterisk) && (
                <span className="form-required">*</span>
              )}
              {isLoading && <Icon loading name="asterisk" />}
            </label>
            <StyledSelect
              className="select form-selectt"
              error={error}
              value={props.entryLanguageValue || ''}
              name={'language'}
              onChange={props.onEntryChange}
              onBlur={props.onBlur}
              disabled={false}
              style={{
                background: 'var(--admincat-color-grey-6)',
                color: isDefaultValueSelected ? props.optionDefaultValueColor : 'var(--admincat-color-grey-2)',
              }}
            >
              <option disabled={!deselectable} value="">
                {tr(props.entryDefaultLanguage || '')}
              </option>
              {/* 
                     set locale option to null,
                     to ensure the languate is always rendered  */}
              <SelectOptions {...props} localeOptions={null} />
            </StyledSelect>
          </div>
        </FormModal>
      </div>
    );
  },
);

NewEntriesSelect.defaultProps = {
  optionDefaultValueColor: 'var(--admincat-color-grey-3)',
};

const ColumnalSelect = compose(
  withFieldError,
  withTranslation,
)(({ tr, className, optionDefaultValue, ...props }) => {
  const isDefaultValueSelected = props.value === "";
  return ( 
    <div className={`form-group user-title ${className}`}>
      <label className="form-label" htmlFor={props.name}>
        {tr(props.label || '')}
        {(props.required || props.showRequiredAsterisk) && (
          <span className="form-required">*</span>
        )}
      </label>

      <BaseColumnalSelect {...props} optionDefaultValue={optionDefaultValue} />
      {props.error && (
        <small>
          <span className="error text-danger">{tr(props.error)}</span>
        </small>
      )}
    </div>
  );
});


const ButtonLink = withTranslation(({ tr, disabled, ...props }) => {
  return (
    <Link
      disabled={disabled}
      onClick={props.onClick || func}
      to={props.to || ''}
      className={`btn block link-button ${props.className || ''}`}
    >
      {props.icon && <Icon name={props.icon} />}
      <span>{tr(props.name)}</span>
    </Link>
  );
});

const SwitcheryWrapper = styled.div`
  margin-top: 1rem;
`;

const Switchery = withTranslation(
  ({
    className,
    value,
    onChange,
    label,
    name,
    id,
    required,
    disabled,
    tr,
    renderRightLabel,
    renderLeftLabel,
    showRightLabel,
  }) => {
    return (
      <SwitcheryWrapper className={`form-group ${className}`}>
        <label className="custom-switch form-label">
          <span
            style={{
              marginRight: '12px',
              marginLeft: '-2px',
              color: 'inherit',
            }}
            className="custom-switch-description"
          >
            {!showRightLabel &&
              renderLeftLabel &&
              renderLeftLabel({ label: tr(label) })}
          </span>
          <input
            type="checkbox"
            name={name}
            id={id || name}
            disabled={disabled}
            checked={value}
            onChange={(e) =>
              onChange({
                target: { name, value: !value },
              })
            }
            className="custom-switch-input"
          />
          <span className="custom-switch-indicator"></span>
          {showRightLabel &&
            renderRightLabel &&
            renderRightLabel({ label: tr(label) })}
          {required && <span className="form-required">*</span>}
        </label>
      </SwitcheryWrapper>
    );
  },
);

const SwitcheryLabel = styled.span`
  display: inline-block;
`;
const SwitcheryLeftLabel = styled(SwitcheryLabel)`
  margin-right: 1rem;
`;
const SwitcheryRightLabel = styled(SwitcheryLabel)`
  margin-left: 1rem;
`;

Switchery.LeftLabel = SwitcheryLeftLabel;
Switchery.RightLabel = SwitcheryRightLabel;

Switchery.defaultProps = {
  value: false,
  className: '',
  renderLeftLabel: ({ label }) => (
    <Switchery.LeftLabel className="LeftLabel">{label}</Switchery.LeftLabel>
  ),
  renderRightLabel: ({ label }) => (
    <Switchery.RightLabel className="RightLabel">{label}</Switchery.RightLabel>
  ),
  showRightLabel: true,
};

const CheckBox = withTranslation(
  ({ value, onChange, label, name, required, className, disabled, tr }) => {
    return (
      <CheckBoxWrapper className={`form-group user-title ${className}`}>
        <label
          onClick={() => {
            onChange({
              target: { name, value: !value },
            });
          }}
          className="form-label"
          htmlFor={name}
        >
          {tr(label)}
          {required && <span className="form-required">*</span>}
        </label>
        <input
          type="checkbox"
          name={name}
          id={name}
          className="custom-control-input"
          onChange={func}
          disabled={disabled}
          checked={value}
        />
        <label
          htmlFor={name}
          onClick={() => {
            onChange({
              target: { name, value: !value },
            });
          }}
          className="checkbox"
        />
      </CheckBoxWrapper>
    );
  },
);

CheckBox.defaultProps = {
  value: false,
  required: false,
  label: '',
  name: '',
  id: '',
  onChange: func,
};

const DualInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    min-width: 200px;
    flex: 1;
    margin-right: 10px;

    :last-child {
      margin-right: 0px;
    }
  }
`;

export {
  Select,
  SelectCountryCode,
  NewEntriesSelect,
  Input,
  ColumnalSelect,
  Button,
  ButtonLink,
  InputDatePicker,
  CheckBox,
  FileInput,
  Form,
  FormModal,
  ViewModal,
  Switchery,
  RadioButtonArray,
  SquareCheckbox,
  DualInputWrapper,
  TextArea,
  RichTextEditor,
  TextEditor,
  Editor,
  FormMode,
  OrgForm,
  FormNotAuth,
  TransactionForm,
};
export { Image } from './Image';
export { FlashMessage } from './FlashMessage';
