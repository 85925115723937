import React from 'react';
import './index.css';
import { useTranslation } from 'react-i18next';
import compose from 'lodash/fp/compose';
import { withTranslation, withAccount } from 'components/hoc';
import styled from 'styled-components';
import Logo from './Admincatlogo.png';
import LogoInverted from './AdminCatinverted.png';
// import Logo from "./logo.png"
// import Twitter from './twitter.svg';

// import XLogo from './x.png';
// import XLogoInverted from './x2.png';
// import FacebookLogo from './facebook.svg';
// import FacebookLogoInverted from './facebook2.png';
import { Link, useHistory, useLocation } from 'react-router-dom';
import DashboardTemplate from '../common/DashboardTemplate';
import routes, { redirects } from './routes';
// import DarkModeToggle from 'components/common/DarkModeToggle';
import { useDarkMode } from 'context/DarkModeContext';

const FooterWrapper = styled.div`
  margin-left: 3rem;
`;
const FooterContainer = styled.footer`
  // background-color: #f8f9fc;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  // background: var(--admincat-color-grey-0);

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const LogoImage = styled.img`
  width: 200px;
  height: auto;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin-right: 20px;
    margin-bottom: 20px;
  }
`;

const FooterLinks = styled.div`
  margin-top: 30px;
  text-align: center;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    li {
      margin: 10px;

      a {
        // color: #000;
        color: var(--admincat-color-grey-2);
        text-decoration: none;
      }
    }
  }
`;

// const SocialMediaLinks = styled.div`
//   margin-top: 30px;

//   ul {
//     list-style: none;
//     padding: 0;
//     margin: 0;
//     display: flex;
//     justify-content: center;

//     @media (min-width: 768px) {
//       justify-content: flex-end;
//     }

//     li {
//       margin: 10px;
//       display: inline-block;
//       width: 15px;

//       a {
//         // color: #000;
//         color: var(--admincat-color-grey-2);
//         text-decoration: none;
//       }
//     }
//   }
// `;

const CopyRightText = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  text-align: center;
  color: var(--admincat-color-grey-2);
`;

const Footer = (props) => {
  const { tr, userPreLang } = props;
  
  // let history = useHistory();
  const location = useLocation();
  const {
    account: {
      user: { isAuth },
    },
  } = props;

  const { t } = useTranslation();
  const [detectLanguage, setDetectLanguage] = React.useState(false);

  const changeLanguageToDefault = (value) => {
    if (userPreLang === 'deu') {
      setDetectLanguage(true);
    } else {
      setDetectLanguage(false);
    }
  };

  React.useEffect(() => {
    changeLanguageToDefault();
  }, [changeLanguageToDefault]);

  const { isDarkMode } = useDarkMode();

  const src = isDarkMode ? LogoInverted : Logo;
  // const facebook = isDarkMode ? FacebookLogoInverted : FacebookLogo;
  // const x_logo = isDarkMode ? XLogoInverted : XLogo;

  const styleColor = {
    color: '#000',
  };

  // Render null if the route is /admin because of the admin ui design
  if (location.pathname === '/admin') {
    return null;
  }

  return (
    <FooterWrapper>
      {isAuth ? (
        <DashboardTemplate
          // style={{background: 'var(--admincat-color-grey-0)'}}
          routes={routes}
          redirects={redirects}
          // render={(renderPropsData) => (
          // <DashboardTemplate.Main {...renderPropsData} />
          // )}
        >
          <FooterContainer>
            <div className="image">
              <div className="image-wrapper">
                <LogoImage src={src} alt="AdminCat" />
              </div>
            </div>
            <FooterLinks>
              <ul>
                <li>
                  <Link to="/disclaimer">{tr('disclaimer')}</Link>
                </li>
                <li>
                  <Link to="/user-terms">{tr('user terms')}</Link>
                </li>
                <li>
                  <span className="privacy">
                    <Link to="/privacy-statement">{tr('privacy policy')}</Link>
                  </span>
                </li>
                <li>
                  <Link to="/faq">{tr('faqs')}</Link>
                </li>
              </ul>
            </FooterLinks>
            {/* <SocialMediaLinks>
              <ul>
                <li>
                  <a
                    href="https://web.facebook.com/AdminCat-112374561255019"
                    target="_blank"
                  >
                    <img src={facebook} alt="Facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/AdmlnCat" target="_blank">
                    <img src={x_logo} alt="X" />
                  </a>
                </li>
              </ul>
            </SocialMediaLinks> */}
            <CopyRightText>
              {detectLanguage ? tr('All rights reserved') : t('All rights reserved')}
            </CopyRightText>
          </FooterContainer>
        </DashboardTemplate>
      ) : (
        <>
          <FooterContainer style={{ backgroundColor: '#fff', color: '#000' }}>
            <div className="image">
              <div className="image-wrapper">
                <LogoImage src={Logo} alt="AdminCat" />
              </div>
            </div>
            <FooterLinks>
              <ul>
                <li>
                  <Link style={styleColor} to="/disclaimer">
                    {t('Disclaimer')}
                  </Link>
                </li>
                <li>
                  <Link style={styleColor} to="/user-terms">
                    {t('User Terms')}
                  </Link>
                </li>
                <li>
                  <span className="privacy">
                    <Link style={styleColor} to="/privacy-statement">
                      {t('Privacy Policy')}
                    </Link>
                  </span>
                </li>
                <li>
                  <Link style={styleColor} to="/faq">
                    {t('FAQs')}
                  </Link>
                </li>
              </ul>
            </FooterLinks>
            {/* <SocialMediaLinks>
              <ul>
                <li>
                  <a
                    href="https://web.facebook.com/AdminCat-112374561255019"
                    target="_blank"
                  >
                    <img src={FacebookLogo} alt="Facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/AdmlnCat" target="_blank">
                    <img src={XLogo} alt="X" />
                  </a>
                </li>
              </ul>
            </SocialMediaLinks> */}
            <CopyRightText style={styleColor}>
              {detectLanguage ? tr('All rights reserved') : t('All rights reserved')}
            </CopyRightText>
          </FooterContainer>
        </>
      )}
    </FooterWrapper>
  );
};

export default compose(withTranslation, withAccount)(Footer);
