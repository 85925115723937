import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MyLink from '../link';
import Img from '../Img';
import { NavWrapper } from './style';
import Logo from './logo.png';
import bars from './bars.svg';

const SUPPORTED_LANGUAGES = ['eng', 'deu'];
const DEFAULT_LANGUAGE = 'eng';

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const [toggleNav, setToggleNav] = useState(false);
  
  // Initialize language from localStorage
  const [language, setLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem('language');
    return SUPPORTED_LANGUAGES.includes(savedLanguage) ? savedLanguage : DEFAULT_LANGUAGE;
  });

  // Set initial language on component mount and handle language changes
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (SUPPORTED_LANGUAGES.includes(savedLanguage)) {
      i18n.changeLanguage(savedLanguage);
      setLanguage(savedLanguage);
    } else {
      // If no saved language, set default
      localStorage.setItem('language', DEFAULT_LANGUAGE);
      i18n.changeLanguage(DEFAULT_LANGUAGE);
      setLanguage(DEFAULT_LANGUAGE);
    }
  }, []); // Empty dependency array means this runs once on mount

  // Handle language changes from user interaction
  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    if (SUPPORTED_LANGUAGES.includes(newLanguage)) {
      localStorage.setItem('language', newLanguage);
      i18n.changeLanguage(newLanguage);
      setLanguage(newLanguage);
    }
  };

  const handleToggle = () => {
    setToggleNav(prev => !prev);
  };

  return (
    <NavWrapper display={toggleNav ? 'flex' : 'none'}>
      <div className="nav-container">
        <div className="nav-image-wrapper">
          <div className="nav-image">
            <Img src={Logo} alt="AdminCat logo" />
          </div>
        </div>

        <div className="nav-buttons">
          <select 
            value={language} 
            onChange={handleLanguageChange}
            className="language-selector"
          >
            <option value="eng">English</option>
            <option value="deu">Deutsch</option>
          </select>
          
          <MyLink white border to="/login">
            {t('Log in')}
          </MyLink>
          <MyLink blue to="/register">
            {t('Get Started')}
          </MyLink>
        </div>
        
        <div className="hambugger" onClick={handleToggle}>
          <Img src={bars} />
        </div>
      </div>
    </NavWrapper>
  );
};

export default NavBar;