import React from 'react';
import styled from 'styled-components';
import { Form as BaseForm, withFormProps } from '../common/Form'
import { Select } from 'components/common/FormItems';


const StyledSelect = styled(Select)`
  padding-right: 2rem;
`;

class Form extends BaseForm {

  educationFormSchema = {
    language: this.required,
    language_verbal: this.required,
    language_literacy: this.required,
  }

  useValidityPeriod = false

  renderUpperInputs = (state) => {
    return (
      <>
        <StyledSelect
          onChange={this.onChange}
          value={state.language}
          name="language"
          label="Language"
          optionDefaultValue="Language"
          options={this.languageOptions}
          showRequiredAsterisk
        />
        <StyledSelect
          onChange={this.onChange}
          value={state.language_verbal}
          label="Proficiency level Listening/Speaking"
          name="language_verbal"
          optionDefaultValue="Proficiency Level"
          options={this.languageLevelOptions}
          showRequiredAsterisk
          sort={false}
        />
        <StyledSelect
          onChange={this.onChange}
          value={state.language_literacy}
          label="Proficiency level Reading/Writing"
          name="language_literacy"
          optionDefaultValue="Proficiency Level"
          options={this.languageLevelOptions}
          showRequiredAsterisk
          sort={false}
        />      
      </>
    )
  }
}

export default withFormProps(Form)

